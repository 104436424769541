const bookingFormCanvas = { background: "padding-box", marginTop: "15rem" };
const loginIconStyle = { fontSize: 25 };
const roomBookingFormBgBlur = {
  backdropFilter: "blur(2px)",
  overflow: "hidden",
};
const activeBookingsCanvasWidth = { width: "70%" };
const activeBookingsTable = { overflowX: "auto", whiteSpace: "nowrap" };
export const styles = {
  bookingFormCanvas,
  loginIconStyle,
  roomBookingFormBgBlur,
  activeBookingsCanvasWidth,
  activeBookingsTable,
};
