import Api from "../api/Api";
import { fetchData } from "./api.service";
import { API_ENDPOINTS } from "../utils/apiEndpoints";
import { ERROR_MESSAGES } from "../utils/constants/errorMessages";
export const googleAuthUrl = (isAdminLogin) => {
  const authUrl = isAdminLogin ? `${Api}/admingooglelogin` : `${Api}/google`;
  window.open(authUrl, "_self");
};
export const logoutUser = async () => {
  try {
    const url = API_ENDPOINTS.COMMON.LOGOUT;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
  }
};
