import { React, useEffect, useState } from "react";
import { getMeetingRooms } from "../../../services/mrbs.service.js";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages";
import { BookRoomCards, DataNotFound, Loading, Pagination } from "../../index";
import "./RoomCard.css";

const RoomCards = () => {
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const userID = JSON.parse(sessionStorage.getItem("ticket"));

  useEffect(() => {
    const getApiData = async () => {
      try {
        const meetRoomsData = await getMeetingRooms();
        setMyData(meetRoomsData);
      } catch (error) {
        console.error(ERROR_MESSAGES.fetchDataError, error);
      } finally {
        setLoading(false);
      }
    };
    getApiData();
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = myData?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {myData?.length === 0 ? (
            <DataNotFound error="Rooms Not Available" />
          ) : (
            <div className="room-cards-container">
              {currentItems?.map((item) => {
                return (
                  <BookRoomCards
                    key={item?.id}
                    id={item?.id}
                    meetRoomId={userID}
                    name={item?.meetRoomName}
                    img={item?.imageUrl}
                    capacity={item?.capacity}
                    status={item?.status}
                    inside={item?.capacity}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
      {myData?.length > 8 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalItems={myData?.length || 0}
          itemsPerPage={itemsPerPage}
        />
      )}
    </>
  );
};

export default RoomCards;
