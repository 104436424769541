import React from "react";
import PropTypes from "prop-types";
function RoomDetailsTable({ userData, isAdminHistory, isSlotDetails }) {
  return (
    <div className="table-responsive">
      <table className="table table-responsive table-striped table-bordered">
        <thead className="thead-dark">
          <tr className="dark-row">
            <th scope="col" className="text-center">
              Sr.No.
            </th>
            {!isAdminHistory && !isSlotDetails && (
              <th scope="col" className="text-center">
                Room Name
              </th>
            )}
            <th scope="col" className="text-center">
              Organizer Name
            </th>
            <th scope="col" className="text-center">
              Title
            </th>{" "}
            {isAdminHistory && (
              <th scope="col" className="text-center">
                Attendees
              </th>
            )}
            <th scope="col" className="text-center">
              Date
            </th>
            <th scope="col" className="text-center">
              Start Time
            </th>
            <th scope="col" className="text-center">
              End Time
            </th>
            <th scope="col" className="text-center">
              Duration
            </th>
          </tr>
        </thead>
        <tbody>
          {userData?.map((item, index) => {
            return (
              <tr key={item?.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                {!isAdminHistory && !isSlotDetails && (
                  <td className="text-center">{item?.meetingRoomName}</td>
                )}
                <td className="text-center">
                  {item?.userName} {item?.lastName}
                </td>
                <td className="text-center">{item?.title}</td>
                {isAdminHistory && (
                  <td className="text-center">{item?.totalAttendees}</td>
                )}
                <td className="text-center">{item?.date}</td>
                <td className="text-center">{item?.startTime}</td>
                <td className="text-center">{item?.endTime}</td>
                <td className="text-center">{item?.duration}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
RoomDetailsTable.propTypes = {
  userData: PropTypes.array.isRequired,
  isAdminHistory: PropTypes.bool,
  isSlotDetails: PropTypes.bool,
};
export default RoomDetailsTable;
