import MapIcon from "@mui/icons-material/Map";
import PropTypes from "prop-types";
import { React, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import TechLogo from "../../../assets/images/new-ta-logo.svg";
import {
  AddNewDesk,
  ConfirmationModal,
  ShowConfirmationPopup,
  UploadFloorPlansImage,
  ViewFloorplansImages,
} from "../../../components";
import {
  DBS_ADMIN_ROUTES,
  DBS_USER_ROUTES,
  MRBS_ADMIN_ROUTES,
  MRBS_USER_ROUTES,
} from "../../../routes/routes";
import { logoutUser } from "../../../services/auth.service.js";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages.js";
import {
  DESK_BOOKING_MESSAGES,
  MODAL_TYPE,
} from "../../../utils/constants/messages.js";
import "./Nav.css";
export default function Nav({
  isMRBSAdmin,
  isDeskBookingUser,
  isDeskBookingAdmin,
  handleCurrentWeekData,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false);
  const [isAddNewDeskModalOpen, setIsAddNewDeskModalOpen] = useState(false);
  const [showFloorplansConfirmation, setShowFloorplansConfirmation] =
    useState(false);
  const navigate = useNavigate();
  const redirectAdminLogin = MRBS_ADMIN_ROUTES.LOGIN;
  const redirectUserLogin = MRBS_USER_ROUTES.LOGIN;
  const handleUploadFloorplansImage = () => {
    setShowFloorplansConfirmation(false);
    setIsUploadImageModalOpen(true);
  };
  const handleAddNewDesk = () => {
    setIsAddNewDeskModalOpen(true);
  };
  const handleCloseAddNewDesk = () => {
    setIsAddNewDeskModalOpen(false);
  };
  const handleUserFloorplans = () => {
    setShowFloorplansConfirmation(false);
    setIsModalOpen(true);
  };
  const handleAdminFloorplans = () => {
    setShowFloorplansConfirmation(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    setShowConfirmation(true);
  };

  const confirmLogout = async () => {
    try {
      if (!isMRBSAdmin) {
        await logoutUser();
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("lastname");
        sessionStorage.removeItem("deskad");
        sessionStorage.removeItem("usr");
        setShowConfirmation(false);
        navigate(redirectUserLogin);
      } else {
        setShowConfirmation(false);
        sessionStorage.removeItem("Admin");
        navigate(redirectAdminLogin);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  };

  let linkComponent;
  let bookingsLink;
  switch (true) {
    case isDeskBookingUser:
      linkComponent = (
        <Link to={DBS_USER_ROUTES.DASHBOARD} className="linkaa">
          Desk Booking
        </Link>
      );
      bookingsLink = (
        <Link to={DBS_USER_ROUTES.BOOKINGS} className="linkaa">
          Bookings
        </Link>
      );
      break;
    case isDeskBookingAdmin:
      linkComponent = (
        <Link to={DBS_ADMIN_ROUTES.DASHBOARD} className="linkaa">
          Desk Booking
        </Link>
      );
      bookingsLink = (
        <Link to={DBS_ADMIN_ROUTES.BOOKINGS} className="linkaa">
          Bookings
        </Link>
      );
      break;
    case isMRBSAdmin:
      linkComponent = (
        <Link to={MRBS_ADMIN_ROUTES.ALL_ROOMS} className="linkaa">
          View All Rooms
        </Link>
      );
      bookingsLink = (
        <Link to={MRBS_ADMIN_ROUTES.ADD_ROOMS} className="linkaa">
          Add Rooms
        </Link>
      );
      break;
    default:
      if (isDeskBookingAdmin) {
        linkComponent = (
          <Link to={DBS_ADMIN_ROUTES.DASHBOARD} className="linkaa">
            Desk Booking
          </Link>
        );
        bookingsLink = (
          <Link to={DBS_ADMIN_ROUTES.BOOKINGS} className="linkaa">
            Bookings
          </Link>
        );
        break;
      }
      linkComponent = (
        <Link to={MRBS_USER_ROUTES.BOOKING} className="linkaa">
          Overview
        </Link>
      );
      bookingsLink = (
        <Link to={MRBS_USER_ROUTES.HISTORY} className="linkaa">
          My Booking
        </Link>
      );
      break;
  }
  return (
    <>
      <nav className="nav">
        <div className="nav-logo-box">
          <Link
            to={
              isMRBSAdmin ? MRBS_ADMIN_ROUTES.ALL_ROOMS : MRBS_USER_ROUTES.HOME
            }
          >
            <img alt="Tech Logo" className="nav-logo" src={TechLogo} />{" "}
          </Link>
        </div>
        <div className="nav-links-box">
          {linkComponent}
          {bookingsLink}
          {isDeskBookingAdmin && (
            <Link onClick={handleAddNewDesk} className="linkaa">
              <div className="link-content-box">
                <HiOutlineViewGridAdd />
                <span>Add New Desk</span>
              </div>
            </Link>
          )}{" "}
          {isDeskBookingUser && (
            <Link className="linkaa" onClick={handleUserFloorplans}>
              <div className="link-content-box">
                <MapIcon />
                <span>Floor Plans</span>
              </div>
            </Link>
          )}{" "}
          {isDeskBookingAdmin && (
            <Link className="linkaa" onClick={handleAdminFloorplans}>
              <div className="link-content-box">
                <MapIcon />
                <span> Floor Plans</span>
              </div>
            </Link>
          )}
        </div>
        <Link className="linkaa" onClick={handleLogout}>
          {" "}
          <div className="link-content-box">
            <BiLogOut className="logout-icon" />
            <span>Logout</span>{" "}
          </div>
        </Link>
      </nav>
      <AddNewDesk
        isOpen={isAddNewDeskModalOpen}
        isClose={handleCloseAddNewDesk}
        reset={handleCurrentWeekData}
      />{" "}
      <ViewFloorplansImages
        isOpen={isModalOpen}
        isClose={handleCloseModal}
        isDeskBookingAdmin={isDeskBookingAdmin}
      />
      <UploadFloorPlansImage
        isOpen={isUploadImageModalOpen}
        isClose={() => setIsUploadImageModalOpen(false)}
      />
      <ShowConfirmationPopup
        isShow={showConfirmation}
        isHide={() => setShowConfirmation(false)}
        isCall={confirmLogout}
      />{" "}
      <ConfirmationModal
        isShow={showFloorplansConfirmation}
        isHide={() => setShowFloorplansConfirmation(false)}
        className={"nav-modal-container"}
        title={"Floor Plans Options"}
        message={
          DESK_BOOKING_MESSAGES.FLOORPLAN_IMAGE_VIEW_OR_UPLOAD_CONFIRMATION
        }
        footerClassName={"nav-modal-footer"}
        btn1text={"View Floor Plans Images"}
        btn2text={"Upload Floor Plans Image"}
        btn1Click={handleUserFloorplans}
        btn2Click={handleUploadFloorplansImage}
        btn1Variant={MODAL_TYPE.SECONDARY}
        btn2Variant={MODAL_TYPE.PRIMARY}
      />{" "}
    </>
  );
}
Nav.propTypes = {
  isMRBSAdmin: PropTypes.bool,
  isDeskBookingUser: PropTypes.bool,
  isDeskBookingAdmin: PropTypes.bool,
  handleCurrentWeekData: PropTypes.func,
};
