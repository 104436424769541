import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalComponent({ isShow, isHide, title, message, variant }) {
  return (
    <Modal show={isShow} onHide={isHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant={variant} onClick={isHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ModalComponent.propTypes = {
  isShow: PropTypes.bool.isRequired,
  isHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

export default ModalComponent;
