import React, { useContext, useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  ConfirmationModal,
  ModalComponent,
  RoomBookingForm,
} from "../../../../components";
import { GuestListContext } from "../../../../context/GuestListContext";
import { MRBS_USER_ROUTES } from "../../../../routes/routes";
import {
  deleteMeetingRoomBookingById,
  editMeetingRoomBookingById,
  getMeetingRoomBookingById,
} from "../../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../../utils/constants/errorMessages";
import {
  MODAL_TYPE,
  MODAL_TYPE_MESSAGE,
  MRBS_MESSAGES,
} from "../../../../utils/constants/messages";
import "./RoomBooking.css";
function CurrentStatusCard(props) {
  const { userId, status } = props;
  const ticket = Number(sessionStorage.getItem("userId"));
  const isOwner = ticket === userId;
  const isActive = status === "Active";
  const [show, setShow] = useState(false);
  const [deleteDataConfirmation, setDeleteDataConfirmation] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editSuccessModalShow, setEditSuccessModalShow] = useState(false);
  const [deleteSuccessModalShow, setDeleteSuccessModalShow] = useState(false);
  const [editNotModalShow, setEditNotModalShow] = useState(false);
  const [editNotValidTimeModalShow, setEditNotValidTimeModalShow] = useState(false);
  const [editErrorModalShow, setEditErrorModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalVariant, setModalVariant] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [modalShowUn, setModalShowUn] = useState(false);
  const navigate = useNavigate();
  const guestList = useContext(GuestListContext);
  const redirectLogin = MRBS_USER_ROUTES.LOGIN;
  useEffect(() => {
    const formattedOptions = guestList?.map((guest) => ({
      guestName: guest.guestName,
      guestEmail: guest.guestEmail,
    }));
    setSuggestions(formattedOptions);
  }, [guestList]);
  const handleNavigateToEdit = () => {
    setShow(false);
  };
  const editCardData = async (id) => {
    try {
      setShow(true);
      const fetchBookingRes = await getMeetingRoomBookingById(id);
      const parts = fetchBookingRes?.date.split("/");
      if (parts?.length === 3) {
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        const converted = `${year}-${month}-${day}`;
        setDate(converted);
      }
      setTitle(fetchBookingRes?.title);
      setStartTime(fetchBookingRes?.startTime);
      setEndTime(fetchBookingRes?.endTime);
      setSelectedOptions(fetchBookingRes?.guests);
      setDescription(fetchBookingRes?.description);
      setIsPrivate(fetchBookingRes?.isPrivate)
    } catch (error) {
      console.error(ERROR_MESSAGES.patchDataError, error);
    }
  };

  const edithandleTitle = (e) => {
    setTitle(e.target.value);
  };

  const edithandleDate = (e) => {
    const selectedDate = e.target.value;

    const today = new Date().toISOString().split("T")[0];
    if (selectedDate < today) {
      setDate(today);
    } else {
      setDate(selectedDate);
    }
  };
  const edithandleStartTime = (e) => {
    setStartTime(e.target.value);
  };

  const edithandleEndtime = (e) => {
    setEndTime(e.target.value);
  };
  const edithandleDescription = (e) => {
    setDescription(e.target.value);
  };

  const onSelectOptions = (selectedList, selectedItem) => {
    if (selectedItem.guestName && selectedItem.guestEmail) {
      const updatedSuggestions = suggestions?.filter(
        (option) => option.guestName !== selectedItem.guestName
      );
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        {
          guestName: selectedItem.guestName,
          guestEmail: selectedItem.guestEmail,
        },
      ]);
      setSuggestions(updatedSuggestions);
    }
  };

  const onRemoveOptions = (selectedList, removedItem) => {
    if (removedItem.guestName && removedItem.guestEmail) {
      if (
        !suggestions.some(
          (option) => option.guestName === removedItem.guestName
        )
      ) {
        suggestions?.push({
          guestName: removedItem.guestName,
          guestEmail: removedItem.guestEmail,
        });
      }

      const updatedSelectedOptions = selectedOptions?.filter(
        (option) => option.guestName !== removedItem.guestName
      );
      setSelectedOptions(updatedSelectedOptions);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);
    const currentDate=new Date();
    const guestsArray = selectedOptions?.map((option) => {
      return { guestName: option.guestName, guestEmail: option.guestEmail };
    });
    const updatedData = {
      meetRoomId: props.meetRoomId,
      title: title,
      date: date.split("-").reverse().join("/"),
      startTime: startTime,
      endTime: endTime,
      guests: guestsArray,
      description: description,
      clstartTime: startDateTime,
      clendTime: endDateTime,
      isPrivate: isPrivate,
      currentDate:currentDate
    };
    const startTimeStamp = new Date(`${date}T${startTime}`).getTime();
    const endTimeStamp = new Date(`${date}T${endTime}`).getTime();
    if (endTimeStamp <= startTimeStamp) {
      setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
      setModalMessage(MRBS_MESSAGES.END_TIME_GREATER_THAN_START_TIME_ERROR);
      setModalVariant(MODAL_TYPE.DANGER);
      setModalShow(true);
      setIsEditing(false);
      return;
    }


    try {
      const editBookingRes = await editMeetingRoomBookingById(
        props.id,
        updatedData
      );
      if (editBookingRes) {
        setEditSuccessModalShow(true);
        setIsEditing(false);
      }
    } catch (error) {

      if (error.response.data.message === MRBS_MESSAGES.ENTER_VALID_TIME) {
        setEditNotValidTimeModalShow(true)
      }else{
      setEditNotModalShow(true);
      }
      setIsEditing(false);
      console.error(ERROR_MESSAGES.patchDataError, error);
    }
  };
  const deleteDataConfirmationclose = () => {
    setDeleteDataConfirmation(false);
  };
  const deleteDataCall = async (id) => {
    setDeleteDataConfirmation(false);

    try {
      const deleteBookings = await deleteMeetingRoomBookingById(id);
      if (deleteBookings) {
        setDeleteSuccessModalShow(true);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.deleteDataError, error);
    }
  };

  const handleCloseModalUn = () => {
    setModalShowUn(false);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    navigate(redirectLogin);
  };
  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleCheckbox = (e) => {
    setIsPrivate(e.target.checked)
  }

  return (
    <>
      <tr key={props.id}>
        <td className="text-center">{props.index + 1}</td>
        <td className="text-center">{props.Rname}</td>
        <td className="text-center">
          {props.Issuer} {props.lname}
        </td>
        <td className="text-center">{props.Date}</td>
        <td className="text-center">{props.from}</td>
        <td className="text-center">{props.to}</td>
        <td className="text-center">
          {isOwner && isActive && (
            <button
              className="room-edit-btn"
              onClick={() => {
                editCardData(props.id);
              }}
            >
              <FiEdit3 />
            </button>
          )}
        </td>
        <td className="text-center">
          {isOwner && isActive && (
            <button
              className="room-edit-btn"
              onClick={() => setDeleteDataConfirmation(true)}
            >
              <AiOutlineDelete />
            </button>
          )}
        </td>
      </tr>
      <RoomBookingForm
        isEdit={true}
        isShow={show}
        isHide={() => setShow(false)}
        isFormSubmit={handleEditSubmit}
        RoomName={props.Rname}
        crossBtn={handleNavigateToEdit}
        title={title}
        handleTitle={edithandleTitle}
        date={date}
        handleDateChange={edithandleDate}
        startTime={startTime}
        handleStartTime={edithandleStartTime}
        endTime={endTime}
        handleEndtime={edithandleEndtime}
        suggestions={suggestions}
        onSelectOptions={onSelectOptions}
        onRemoveOptions={onRemoveOptions}
        selectedOptions={selectedOptions}
        description={description}
        handleDescription={edithandleDescription}
        isBooking={isEditing}
        isPrivate={isPrivate}
        handleCheckbox={handleCheckbox}
      />
      <ModalComponent
        isShow={editErrorModalShow}
        isHide={() => setEditErrorModalShow(false)}
        title={MODAL_TYPE_MESSAGE.ERROR}
        message={
          MRBS_MESSAGES.MEETING_ROOM_CANNOT_EDITED_LESS_THAN_ONE_HOUR_ERROR
        }
        variant={MODAL_TYPE.PRIMARY}
      />
      <ConfirmationModal
        isShow={deleteDataConfirmation}
        isHide={deleteDataConfirmationclose}
        title={MODAL_TYPE.CONFIRMATION}
        message={MRBS_MESSAGES.BOOKING_DELETE_CONFIRMATION}
        btn1text={"Yes"}
        btn2text={"No"}
        btn1Click={() => deleteDataCall(props.id)}
        btn2Click={deleteDataConfirmationclose}
        btn1Variant={MODAL_TYPE.DANGER}
        btn2Variant={MODAL_TYPE.PRIMARY}
      />{" "}
      <ModalComponent
        isShow={editSuccessModalShow}
        isHide={() => {
          setEditSuccessModalShow(false);
          props.onUpdate();
          setShow(false);
        }}
        title={MODAL_TYPE_MESSAGE.SUCCESS}
        message={MRBS_MESSAGES.BOOKING_EDITED_SUCCESFULLY}
        variant={MODAL_TYPE.PRIMARY}
      />
      <ModalComponent
        isShow={editNotModalShow}
        isHide={() => setEditNotModalShow(false)}
        title={"Cancel"}
        message={MRBS_MESSAGES.MEETING_ROOM_SLOT_ALREADY_OCCUPIED}
        variant={MODAL_TYPE.DANGER}
      />
      <ModalComponent
        isShow={editNotValidTimeModalShow}
        isHide={() => setEditNotValidTimeModalShow(false)}
        title={"Cancel"}
        message={MRBS_MESSAGES.ENTER_VALID_TIME}
        variant={MODAL_TYPE.DANGER}
      />
      <ModalComponent
        isShow={deleteSuccessModalShow}
        isHide={() => {
          setDeleteSuccessModalShow(false);
          props.onUpdate();
        }}
        title={MODAL_TYPE_MESSAGE.SUCCESS}
        message={MRBS_MESSAGES.BOOKING_DELETED_SUCCESFULLY}
        variant={MODAL_TYPE.PRIMARY}
      />
      <ModalComponent
        isShow={modalShow}
        isHide={handleCloseModal}
        title={modalTitle}
        message={modalMessage}
        variant={modalVariant}
      />
      <ModalComponent
        isShow={modalShowUn}
        isHide={handleCloseModalUn}
        title={modalTitle}
        message={modalMessage}
        variant={modalVariant}
      />
    </>
  );
}
export default CurrentStatusCard;
