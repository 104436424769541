import PropTypes from "prop-types";
import React from "react";
import "../Loading/Loading.css";

export default function DataNotFound({ error }) {
  return <div className="data-not-found">{error}</div>;
}
DataNotFound.propTypes = {
  error: PropTypes.string.isRequired,
};
