import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import { getMeetingRoomHistoryById } from "../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages";
import { DataNotFound, Loading, Nav, RoomDetailsTable } from "../../index";
import "./AdminRoomCards.css";
function AdminRoomDetailsCard() {
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchCardData = async () => {
      /* The `useEffect` is responsible for fetching data related to a specific meeting room's booking history when the component mounts or when the `id` parameter changes.*/
      try {
        const cardDataRes = await getMeetingRoomHistoryById(id);
        setCardData(cardDataRes);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };
    fetchCardData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Nav isMRBSAdmin={true} />
      <div className="room-details-container">
        <div className="allroom room-details">
          <h1>Room Booking History</h1>
          <div>
            <button
              onClick={handleGoBack}
              className="me-2 room-booking-history-btn"
            >
              <GrClose />
            </button>
          </div>
        </div>

        {cardData?.length === 0 ? (
          <DataNotFound error="Details Not Found" />
        ) : (
          <RoomDetailsTable userData={cardData} isAdminHistory={true} />
        )}
      </div>
    </>
  );
}

export default AdminRoomDetailsCard;
