import React, { useEffect, useState } from "react";
import { MRBS_ADMIN_ROUTES } from "../../../routes/routes";
import { getMeetingRooms } from "../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages";
import { AdminRoomCardMain, DataNotFound, Loading } from "../../index";
import "./AdminRoomCards.css";
const AdminRoomCard = () => {
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = myData?.slice(indexOfFirstItem, indexOfLastItem);

  /* The function `getMeetingRoomsData` fetches meeting rooms data asynchronously and updates the state with the fetched data.*/

  const getMeetingRoomsData = async () => {
    try {
      const meetRoomsRes = await getMeetingRooms(true);
      setMyData(meetRoomsRes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  };
  const onUpdate = () => {
    getMeetingRoomsData();
  };
  useEffect(() => {
    getMeetingRoomsData();
  }, []);

  return (
    <>
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            {myData?.length === 0 ? (
              <DataNotFound error="Rooms Not Found" />
            ) : (
              <div className="room-cards-container">
                {currentItems?.map((item) => {
                  return (
                    <AdminRoomCardMain
                      key={item?.id}
                      id={item?.id}
                      Rname={item?.meetRoomName}
                      img={item?.imageUrl}
                      inside={item?.capacity}
                      status={item?.status}
                      path={`${MRBS_ADMIN_ROUTES.ROOM_DETAILS_PAGE}/${item.id}`}
                      onUpdate={onUpdate}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </>

      {myData?.length > 8 && (
        <div className="hispagination_admin">
          <button
            className="hisbuttonpre_admin"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          >
            Previous
          </button>
          <span>{currentPage}</span>
          <button
            className="hisbuttonnxt_admin"
            disabled={currentPage === Math.ceil(myData?.length / itemsPerPage)}
            onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default AdminRoomCard;
