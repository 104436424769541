import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from "prop-types";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import LoginBg from "../../../assets/images/loginbg.png";
import TaLogo from "../../../assets/images/TechAlchemy-black.png";
import { COMMON_ROUTES } from "../../../routes/routes";
import { googleAuthUrl } from "../../../services/auth.service";
import { styles } from "../../styleSheet";
import "./SignIn.css";

const SignIn = ({ isAdminLogin }) => {
  const googleAuth = () => {
    googleAuthUrl(isAdminLogin);
  };
  return (
    <section className="login-container">
      <div className="signin-content">
        <img className="ta-logo" src={TaLogo} alt="Tech Alchemy Logo" />
        {!isAdminLogin ? (
          <h2 className="login-title">
            Hi there!
            <br /> Welcome back to MRBS and Desk Booking
          </h2>
        ) : (
          <h2 className="login-title">Welcome to MRBS Admin Hub</h2>
        )}
        <button className="google-login-btn" onClick={googleAuth}>
          <FcGoogle style={styles.loginIconStyle} />
          <div>Log in with Google</div>
        </button>{" "}
        {isAdminLogin && (
          <h3 className="redirect-page">
            {" "}
            Explore User Login{" "}
            <Link className="redirect-link" to={COMMON_ROUTES.LOGIN}>
              <OpenInNewIcon style={styles.loginIconStyle} />
            </Link>{" "}
          </h3>
        )}
      </div>{" "}
      <div className="image-container">
        <img className="login-bg-image" src={LoginBg} alt="backgound img" />
      </div>
    </section>
  );
};
SignIn.propTypes = {
  isAdminLogin: PropTypes.bool,
};

export default SignIn;
