export const COMMON_MESSAGES = {
  PAGE_NOT_FOUND: "Page Not Found",
  CONFIRMATION_LOGOUT_MESSAGE: "Are you sure you want to logout?",
  SELECT_IMAGE_FILE_WITH_SIZE_LIMIT_ERROR:
    "Please select an image file with size up to 10 MB.",
  SELECT_VALID_IMAGE_FILE_ERROR:
    "Please select a valid image file (JPEG or PNG).",
};
export const MRBS_MESSAGES = {
  END_TIME_GREATER_THAN_START_TIME_ERROR:
    "End time should be greater than the start time.",
  CANNOT_EDIT_PAST_TIME_ERROR: "You cannot edit a slot in the past time.",
  CANNOT_DELETE_PAST_TIME_ERROR:
    "You cannot delete a meeting slot in the past time.",
  TITLE_ONLY_LETTERS_ERROR: "Title must contain only letters.",
  MEETING_ROOM_NAME_CHARACTERS_ERROR:
    "Meet Room Name length must be at least 3 characters long",
  CANNOT_BOOK_PAST_SLOT_ERROR: "You cannot book a slot in the past.",
  MEETING_ROOM_SLOT_ALREADY_OCCUPIED: "Meeting slot is already occupied",
  ENTER_VALID_TIME: "Please Enter Valid Time",
  MEETING_ROOM_ADDED_SUCCESSFULLY_MESSAGE: "Meeting Room added successfully.",
  MEETING_ROOM_DELETED_SUCCESSFULLY_MESSAGE: "Room Deleted successfully",
  MEETING_ROOM_EDITED_SUCCESSFULLY_MESSAGE: "Data edited successfully",
  MEETING_ROOM_BOOKED_SUCCESSFULLY_MESSAGE: "Meeting Room Booked Successfully.",
  MEETING_ROOM_DELETE_CONFIRMATION_MESSAGE:
    "Are you sure you want to Delete Room ?",
  MEETING_ROOM_CANNOT_EDITED_LESS_THAN_ONE_HOUR_ERROR:
    "This meeting cannot be edited as it is less than one hour from now.",
  BOOKING_DELETE_CONFIRMATION: "Are you sure you want to delete?",
  BOOKING_EDITED_SUCCESFULLY: "Data Edited successfully",
  BOOKING_DELETED_SUCCESFULLY: "Booking Deleted Successfully",
};
export const DESK_BOOKING_MESSAGES = {
  success: {
    "Your Booking completed successfully": "Desk Booking Successful !",
    "Desk Added Succesfully...": "Desk Added Succesfully...",
    "Desk Edited Succesfully...": "Desk Edited Succesfully...",
    "Desk deleted Successfully": "Desk Deleted Successfully...",
    "Floor Plan Image Added Successfully...":
      "Floor Plan Image Added Successfully...",
    "Floor Plan Image Edited Successfully...":
      "Floor Plan Image Edited Successfully...",
  },
  error: {
    "Already Booked...": "Desk has been booked already !",
    "Desk Already Booked For You...": "Desk has been booked already !",
  },
  DESK_BOOKING_COMPLETED_SUCCESFULLY: "Your Booking completed successfully",
  DESK_EDITED_SUCCESFULLY: "Desk Edited Succesfully...",
  DESK_ADDED_SUCCESFULLY: "Desk Added Succesfully...",
  DESK_DELETED_SUCCESFULLY: "Desk deleted Successfully",
  FLOOR_PLAN_IMAGE_ADDED_SUCCESFULLY: "Floor Plan Image Added Successfully...",
  FLOOR_PLAN_IMAGE_EDITED_SUCCESFULLY:
    "Floor Plan Image Edited Successfully...",
  DESK_NOT_DELETED: "Desk is Not Deleted",
  DELETE_ALL_BOOKINGS_CONFIRMATION: "Delete this booking and future bookings?",
  FLOORPLAN_IMAGE_VIEW_OR_UPLOAD_CONFIRMATION:
    "Would you like to view the floor plans image or upload a new one?",
};
export const MODAL_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  DANGER: "danger",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DARK: "dark",
  LIGHT: "light",
  CONFIRMATION: "Confirmation",
};
export const MODAL_TYPE_MESSAGE = {
  SUCCESS: "Success",
  ERROR: "Error",
};
export const ROOM_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
  Inactive: "Inactive",
};
export const DESK_TYPE = {
  HOTDESK: "Hot Desk",
  GENERAL_AREA: "General Area",
  DEDICATED: "Dedicated",
};
export const DESK_BOOKING_TYPE = {
  TODAY: "today",
  UPCOMING: "upcoming",
  PAST: "past",
  CUSTOM_DAYS: "customDays",
  EVERYDAY: "everyDay",
  EVERY_SECOND_DAY: "every2ndDay",
};
