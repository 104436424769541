import React from "react";
import { ActiveBooking, Nav, RoomCards } from "../../../../components";
import "./RoomBooking.css";

const RoomBooking = () => {
  return (
    <>
      <Nav />
      <div className="dash-room-container">
        <div className="dash-room-heading-box">
          <div className="room-details-heading">
            <h1>Book a Meeting Room</h1>
            {["end"].map((placement, idx) => (
              <ActiveBooking key={idx} placement={placement} name={placement} />
            ))}
          </div>
        </div>
        <RoomCards />
      </div>
    </>
  );
};

export default RoomBooking;
