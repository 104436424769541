import axios from "axios";
import Api from "../api/Api";

import {
  ERROR_MESSAGES,
  STATUS_CODE_MESSAGES,
} from "../utils/constants/errorMessages";

// Function to clear all data from session storage
const clearSessionStorage = () => {
  sessionStorage.clear();
};
const axiosInstance = axios.create({
  baseURL: Api,
});

/* The `axiosInstance.interceptors.request.use()` is setting up a request interceptor for the Axios instance `axiosInstance`. */
axiosInstance.interceptors.request.use(
  (config) => {
    const userToken = JSON.parse(sessionStorage.getItem("token"));
    const adminToken = JSON.parse(sessionStorage.getItem("Admin"));
    const token = userToken || adminToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      // config.headers["ngrok-skip-browser-warning"] = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* Here is setting up a response interceptor for the Axios instance `axiosInstance`. */
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      clearSessionStorage();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

/* The `fetchData` function fetches data from a specified URL using axios and handles errors with optional chaining and status code messages and returning the `result` property from the data object in the response received from the specified URL.
 */
export const fetchData = async (url) => {
  try {
    const res = await axiosInstance.get(url);
    return res?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

/* The `postData` function sends a POST request to a specified URL with an initial request body and handles errors appropriately and returning the `result` property from the `data` object in the response. */
export const postData = async (url, initialRequestBody) => {
  try {
    const response = await axiosInstance.post(url, initialRequestBody);
    return response?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.postDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

/* The function `postMultiFormData` sends a POST request with multipart form data and handles errors appropriately and returning the `result` property from the `data` object in the response. */
export const postMultiFormData = async (url, initialRequestBody) => {
  try {
    const response = await axiosInstance.post(url, initialRequestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.postMultiFormDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};
/* The function `patchMultiFormData` sends a PATCH request with updated form data to a specified URL and handles any errors that occur and returning the `result` property from the `data` object in the response. */
export const patchMultiFormData = async (url, updatedRequestBody) => {
  try {
    const response = await axiosInstance.patch(url, updatedRequestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.patchMultiFormDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

/* The `patchData` function sends a PATCH request to a specified URL with an initial request body and returns the result data from the response. */
export const patchData = async (url, initialRequestBody) => {
  try {
    const response = await axiosInstance.patch(url, initialRequestBody);
    return response?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.patchDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

/* The function `deleteData` makes an asynchronous request to delete data from a specified URL using axios, handling errors and returning the result if successful. */
export const deleteData = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response?.data?.result;
  } catch (error) {
    console.error(ERROR_MESSAGES.deleteDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

/* The `postDeskData` function sends a POST request to a specified URL with initial data and returns the result from the response. */
export const postDeskData = async (url, initialRequestBody) => {
  try {
    const response = await axiosInstance.post(url, initialRequestBody);
    return response;
  } catch (error) {
    console.error(ERROR_MESSAGES.postDeskDataError, error);
    if (error?.response?.status) {
      console.error("Status Code:", error.response.status);
      console.error("Status:", STATUS_CODE_MESSAGES[error.response.status]);
    }
    throw error;
  }
};

export default axiosInstance;
