import PropTypes from "prop-types";
import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  deleteMeetingRoomById,
  editMeetingRoomById,
  getMeetingRoomById,
} from "../../../services/mrbs.service.js";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages.js";
import {
  MODAL_TYPE,
  MODAL_TYPE_MESSAGE,
  MRBS_MESSAGES,
  ROOM_STATUS,
} from "../../../utils/constants/messages.js";
import {
  getStatusColor,
  regexPatterns
} from "../../../utils/helpers.js";
import { ConfirmationModal, ModalComponent } from "../../index";
import { styles } from "../../styleSheet.js";
import CreateRoomForm from "../Form/CreateRoomForm.jsx";
import "./AdminRoomCards.css";
function AdminRoomCardMain(props) {
  const [show, setShow] = useState(false);
  const [deleteDataConfirmation, setDeleteDataConfirmation] = useState(false);
  const [editSuccessModalShow, setEditSuccessModalShow] = useState(false);
  const [editNotModalShow, setEditNotModalShow] = useState(false);
  const [meetRoomName, setMeetRoomName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [status, setStatus] = useState("Active");
  const [imageUrl, setImageUrl] = useState(null);
  const [roomNameError, setRoomNameError] = useState(false);
  const [deleteSuccessModalShow, setDeleteSuccessModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleName = (e) => {
    setMeetRoomName(e.target.value);
  };

  const handleCapacity = (e) => {
    setCapacity(e.target.value);
  };

  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    setImageUrl(selectedFile);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  /* The `editRoomData` function fetches meeting room data by ID and updates the state with the retrieved information.*/
  const editRoomData = async (id) => {
    try {
      setShow(true);
      const fetchRoomRes = await getMeetingRoomById(id);
      setMeetRoomName(fetchRoomRes?.meetRoomName);
      setCapacity(fetchRoomRes?.capacity);
      setStatus(fetchRoomRes?.status);
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  };

  /* The function `handleEditSubmit` is used to handle the submission of edited meeting room data and returning either the success modal being shown or the error modal being shown.*/
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (meetRoomName.length < 3) {
      setRoomNameError(true);
      return;
    }
    setIsEditing(true);

    const formData = new FormData();
    formData.append("meetRoomName", meetRoomName);
    formData.append("capacity", capacity);
    if (imageUrl) {
      formData.append("imageurl", imageUrl);
    }
    formData.append("status", status);

    const regex = regexPatterns.lettersAndSpacesRegex;
    if (!regex.exec(meetRoomName)) {
      setShowModal(MODAL_TYPE_MESSAGE.ERROR);
      setModalMessage(MRBS_MESSAGES.TITLE_ONLY_LETTERS_ERROR);
      setModalType(MODAL_TYPE.DANGER);
      setShowModal(true);
      setIsEditing(false);
      return;
    }
    try {
      const editRoomRes = await editMeetingRoomById(props.id, formData);
      if (editRoomRes) {
        setEditSuccessModalShow(true);
        setIsEditing(false);
      }
    } catch (error) {
      setEditNotModalShow(true);
      setIsEditing(false);
      console.error(ERROR_MESSAGES.patchDataError, error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShow(false);
    props.onUpdate();
  };

  const handleNavigateToAdminRooms = () => {
    setShow(false);
  };

  /* The function `deleteDataCall` is an asynchronous function that deletes a meeting room by its ID and handles success and error cases accordingly.*/
  const deleteDataCall = async (id) => {
    try {
      setDeleteDataConfirmation(false);
      await deleteMeetingRoomById(id);
      setDeleteSuccessModalShow(true);
    } catch (error) {
      setDeleteSuccessModalShow(true);
      console.error(ERROR_MESSAGES.deleteDataError, error);
    }
  };
  const deleteDataConfirmationclose = () => {
    setDeleteDataConfirmation(false);
  };
  const statusColor = getStatusColor(props.status);
  const statusStyle = {
    color: statusColor,
    borderColor: statusColor,
  };
  return (
    <>
      <div className="room-card-box">
        <img alt="img" className="meeting-room-img" src={props.img} />
        <div className="meeting-room-details">
          <div className="room-card-name-box">
            <h1 className="room-card-name">{props.Rname}</h1>
            <div className="room-card-status" style={statusStyle}>
              {props.status === ROOM_STATUS.ACTIVE
                ? ROOM_STATUS.ACTIVE
                : ROOM_STATUS.Inactive}
            </div>
          </div>
          <div className="room-card-capicity">
            Capacity: {props.inside}
            <div className="edit-delete-btn">
              <button
                onClick={() => {
                  editRoomData(props.id);
                }}
              >
                <FiEdit3 />
              </button>
              <button onClick={() => setDeleteDataConfirmation(true)}>
                <AiOutlineDelete />
              </button>
            </div>
          </div>{" "}
          <Link to={props.path}>
            <Button className="view-meeting-history-btn" variant="dark">
              View Meeting History
            </Button>
          </Link>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        style={styles.bookingFormCanvas}
      >
        <Offcanvas.Body>
          <div className="booking-form-card">
            <CreateRoomForm
              isEdit={true}
              handleSubmit={handleEditSubmit}
              meetRoomName={meetRoomName}
              handleName={handleName}
              handleImage={handleImage}
              capacity={capacity}
              handleStatus={handleStatus}
              status={status}
              handleCapacity={handleCapacity}
              isAdding={false}
              isEditing={isEditing}
              handleNavigateToAdminRooms={handleNavigateToAdminRooms}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ConfirmationModal
        isShow={deleteDataConfirmation}
        isHide={deleteDataConfirmationclose}
        title={MODAL_TYPE.CONFIRMATION}
        message={MRBS_MESSAGES.MEETING_ROOM_DELETE_CONFIRMATION_MESSAGE}
        btn1text={"Yes"}
        btn2text={"No"}
        btn1Click={() => deleteDataCall(props.id)}
        btn2Click={deleteDataConfirmationclose}
        btn1Variant={MODAL_TYPE.DANGER}
        btn2Variant={MODAL_TYPE.PRIMARY}
      />
      <ModalComponent
        isShow={editNotModalShow}
        isHide={() => setEditNotModalShow(false)}
        title={ERROR_MESSAGES.networkError}
        variant={MODAL_TYPE.DANGER}
      />
      <ModalComponent
        isShow={roomNameError}
        isHide={() => setRoomNameError(false)}
        message={MRBS_MESSAGES.MEETING_ROOM_NAME_CHARACTERS_ERROR}
        variant={MODAL_TYPE.DANGER}
      />
      <ModalComponent
        isShow={deleteSuccessModalShow}
        isHide={() => {
          props.onUpdate();
          setDeleteSuccessModalShow(false);
        }}
        title={"Success"}
        message={MRBS_MESSAGES.MEETING_ROOM_DELETED_SUCCESSFULLY_MESSAGE}
        variant={MODAL_TYPE.PRIMARY}
      />{" "}
      <ModalComponent
        isShow={editSuccessModalShow}
        isHide={() => {
          handleCloseModal();
          setEditSuccessModalShow(false);
        }}
        title={MODAL_TYPE_MESSAGE.SUCCESS}
        message={MRBS_MESSAGES.MEETING_ROOM_EDITED_SUCCESSFULLY_MESSAGE}
        variant={MODAL_TYPE.PRIMARY}
      />
      <ModalComponent
        isShow={showModal}
        isHide={closeModal}
        title={
          modalType === MODAL_TYPE.SUCCESS
            ? MODAL_TYPE_MESSAGE.SUCCESS
            : MODAL_TYPE_MESSAGE.ERROR
        }
        message={modalMessage}
        variant={
          modalType === MODAL_TYPE.SUCCESS
            ? MODAL_TYPE.SUCCESS
            : MODAL_TYPE.DANGER
        }
      />
    </>
  );
}
AdminRoomCardMain.propTypes = {
  id: PropTypes.number.isRequired,
  Rname: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  inside: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
export default AdminRoomCardMain;
