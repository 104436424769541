export const ERROR_MESSAGES = {
  fetchDataError: "Error fetching Data:",
  postDataError: "Error for post data:",
  postMultiFormDataError: "Error for post multi-form data:",
  patchDataError: "Error for patch data:",
  deleteDataError: "Error deleting data:",
  postDeskDataError: "Error for post desk data:",
  networkError: "Network Error",
  somethingWrongError: "Oops, something went wrong. Please try again later.",
  unauthorizedError: "Unauthorized. Please log in again",
};

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};

export const STATUS_CODE_MESSAGES = {
  [STATUS_CODES.OK]: "OK",
  [STATUS_CODES.BAD_REQUEST]: "Bad Request",
  [STATUS_CODES.UNAUTHORIZED]: "Unauthorized",
  [STATUS_CODES.INTERNAL_SERVER_ERROR]: "Internal Server Error",
};
