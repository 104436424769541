import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import {
  deleteFloorPlanById,
  getFloorPlans,
} from "../../services/deskBooking.service";
import { ERROR_MESSAGES } from "../../utils/constants/errorMessages";
import { MODAL_TYPE, MODAL_TYPE_MESSAGE } from "../../utils/constants/messages";
import { UploadFloorPlansImage } from "../index";
import { styles } from "./styleSheet";

function ViewFloorplansImages({ isOpen, isClose, isDeskBookingAdmin }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [noData, setNoData] = useState(false);
  const [uploadImagePopup, setUploadImagePopup] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [floorImageDelete, setFloorImageDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [imagesFetched, setImagesFetched] = useState(false);

  /* This `useEffect` hook is used to set the `currentImage` state to 0 whenever the `isOpen` prop changes. */
  useEffect(() => {
    if (isOpen) {
      setCurrentImage(0);
      getFloorPlansImages();
    }
  }, [isOpen]);

  /* The function `getFloorPlansImages` fetches floor plan images from a specified URL using a token for authentication and handles errors appropriately.*/
  const getFloorPlansImages = async () => {
    try {
      const floorPlansRes = await getFloorPlans();
      if (floorPlansRes?.length === 0) {
        setNoData(true);
      } else {
        const fetchedImages = floorPlansRes?.map((item) => ({
          id: item?.Id,
          imageName: item?.ImageName,
          imagePath: item?.Imagepath,
        }));
        setImages(fetchedImages);
        setImagesFetched(true);
      }
    } catch (error) {
      setNoData(true);
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  };

  /* This `useEffect` hook is responsible for fetching floor plan images using the
  `getFloorPlansImages` function when the component is opened (`isOpen` is true) and the images have not been fetched yet (`imagesFetched` is false).*/
  useEffect(() => {
    if (!isOpen || imagesFetched) return;
    if (!isOpen) {
      getFloorPlansImages();
    }
  }, [isOpen, currentImage, isDeskBookingAdmin, imagesFetched]);

  const openImageViewer = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  /* The function `handlePrevImage` decreases the current image index by 1 if it is greater than 0.*/
  const handlePrevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  /* The function `handleNextImage` increments the current image index by 1 if it is not already at the last image.*/
  const handleNextImage = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  const handleDeleteImage = async (id) => {
    setSelectedImageId(id);
    setFloorImageDelete(true);
  };

  /* The function `confirmDeleteImage` deletes an image from a floorplan and displays a success or error message in a modal.*/
  const confirmDeleteImage = async () => {
    try {
      const deleteDataRes = await deleteFloorPlanById(selectedImageId);
      setSelectedImageId(null);
      setShowModal(true);
      setModalMessage(deleteDataRes?.message);
      setModalType(MODAL_TYPE.SUCCESS);
      getFloorPlansImages();
      setCurrentImage(0);
    } catch (error) {
      setShowModal(true);
      setModalMessage(error.response.data.message);
      setModalType(MODAL_TYPE.ERROR);
      console.error(ERROR_MESSAGES.deleteDataError, error);
    }
  };
  const handleUpload = (id) => {
    setSelectedImageId(id);
    setUploadImagePopup(true);
  };

  const handleCancel = () => {
    isClose();
  };

  return (
    <>
      <Modal show={isOpen} onHide={isClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="booking-form-heading">View Floor Plans Images</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalViewFloorPlansImg}>
          <div style={styles.viewFloorPlansImgContainer}>
            <Button disabled={currentImage === 0} onClick={handlePrevImage}>
              <ArrowCircleLeftIcon />
            </Button>{" "}
            <span style={styles.imageNotFoundText}>
              {noData ? "Image Not Found" : images[currentImage]?.imageName}
            </span>{" "}
            <Button
              disabled={
                currentImage === images.length - 1 ||
                images.length === 1 ||
                noData
              }
              onClick={handleNextImage}
            >
              <ArrowCircleRightIcon />
            </Button>
          </div>
          {!noData && (
            <img
              src={images[currentImage]?.imagePath}
              onClick={openImageViewer}
              style={styles.viewFloorPlansImg}
              alt="Floor Plans"
            />
          )}

          {isViewerOpen && (
            <ImageViewer
              src={images?.map((image) => image?.imagePath)}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              zoomStep={0.1}
              showZoomControls={true}
              maxZoom={5}
            />
          )}
          {isDeskBookingAdmin && (
            <div style={styles.editFloorPlanBtn}>
              <Button
                variant={MODAL_TYPE.PRIMARY}
                disabled={noData}
                onClick={() => handleUpload(images[currentImage]?.id)}
              >
                Edit
              </Button>{" "}
              <Button
                variant={MODAL_TYPE.DANGER}
                disabled={noData}
                onClick={() => handleDeleteImage(images[currentImage]?.id)}
              >
                Delete
              </Button>{" "}
              <Button variant={MODAL_TYPE.SECONDARY} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <UploadFloorPlansImage
        isEdit={true}
        isOpen={uploadImagePopup}
        isClose={() => setUploadImagePopup(false)}
        selectedImageId={selectedImageId}
        resetFloorPlan={getFloorPlansImages}
      />
      <Modal
        className="nav-modal-container"
        show={floorImageDelete}
        onHide={() => setFloorImageDelete(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Floor Plans Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this floor plan image?
        </Modal.Body>
        <Modal.Footer className="nav-modal-footer">
          <Button
            variant={MODAL_TYPE.SECONDARY}
            onClick={() => {
              setFloorImageDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={MODAL_TYPE.DANGER}
            onClick={() => {
              confirmDeleteImage();
              setFloorImageDelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === MODAL_TYPE.SUCCESS
              ? MODAL_TYPE_MESSAGE.SUCCESS
              : MODAL_TYPE_MESSAGE.ERROR}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant={
              modalType === MODAL_TYPE.SUCCESS
                ? MODAL_TYPE.SUCCESS
                : MODAL_TYPE.DANGER
            }
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
ViewFloorplansImages.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isClose: PropTypes.func.isRequired,
  isDeskBookingAdmin: PropTypes.bool,
};
export default ViewFloorplansImages;
