import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddRooms,
  AdminSignIn,
  AllRooms,
  BookingHistory,
  DataNotFound,
  ProtectedRoute,
  RoomBooking,
  RoomDetails,
  UserDashboard,
  UserSignIn,
} from "./components";

import {
  COMMON_ROUTES,
  MRBS_ADMIN_ROUTES,
  MRBS_USER_ROUTES,
} from "./routes/routes";
import { COMMON_MESSAGES } from "./utils/constants/messages";
const App = () => {
  return (
    <Routes>
      {/* MRBS User Routes */}
      <Route path={COMMON_ROUTES.LOGIN} element={<UserSignIn />} />{" "}
      <Route path={MRBS_USER_ROUTES.LOGIN} element={<UserSignIn />} />
      <Route
        path={MRBS_USER_ROUTES.HOME}
        element={
          <ProtectedRoute isMRBSUser={true} Components={UserDashboard} />
        }
      />
      <Route
        path={MRBS_USER_ROUTES.BOOKING}
        element={<ProtectedRoute isMRBSUser={true} Components={RoomBooking} />}
      />
      <Route
        path={MRBS_USER_ROUTES.HISTORY}
        element={
          <ProtectedRoute isMRBSUser={true} Components={BookingHistory} />
        }
      />
      {/* MRBS Admin Routes */}
      <Route path={MRBS_ADMIN_ROUTES.LOGIN} element={<AdminSignIn />} />
      <Route
        path={MRBS_ADMIN_ROUTES.ALL_ROOMS}
        element={<ProtectedRoute isMRBSAdmin={true} Components={AllRooms} />}
      />
      <Route
        path={MRBS_ADMIN_ROUTES.ADD_ROOMS}
        element={<ProtectedRoute isMRBSAdmin={true} Components={AddRooms} />}
      />
      <Route
        path={MRBS_ADMIN_ROUTES.ROOM_DETAILS}
        element={<ProtectedRoute isMRBSAdmin={true} Components={RoomDetails} />}
      />
      {/* DBS User Routes */}{" "}
      {/* <Route
        path={DBS_USER_ROUTES.DASHBOARD}
        element={
          <ProtectedRoute isDBSUser={true} Components={UserDeskBooking} />
        }
      />
      <Route
        path={DBS_USER_ROUTES.BOOKINGS}
        element={
          <ProtectedRoute isDBSUser={true} Components={UserBookingHistory} />
        }
      /> */}
      {/* DBS Admin Routes */}
      {/* <Route
        path={DBS_ADMIN_ROUTES.DASHBOARD}
        element={
          <ProtectedRoute isDBSAdmin={true} Components={AdminDeskBooking} />
        }
      />
      <Route
        path={DBS_ADMIN_ROUTES.BOOKINGS}
        element={
          <ProtectedRoute isDBSAdmin={true} Components={AdminBookingHistory} />
        }
      /> */}
      {/* Page Not Found Route */}
      <Route
        path={COMMON_ROUTES.PAGE_NOT_FOUND}
        element={<DataNotFound error={COMMON_MESSAGES.PAGE_NOT_FOUND} />}
      />
    </Routes>
  );
};

export default App;
