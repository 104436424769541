export const API_ENDPOINTS = {
  COMMON: {
    LOGOUT: "/logout",
    GUEST_LIST: "/GetAllGuests",
  },
  MRBS: {
    USER_MEETROOMS: "/meetrooms",
    USER_MEETROOMS_ACTIVE: "/meetrooms?panel=User",
    USER_BOOKINGS: "/bookings",
    USER_BOOK_ROOM: "/bookroom",
    USER_MY_BOOKINGS: "/mybookings",
    USER_BOOKING_WITH_ID: "/fetchBookingWithId",
    USER_EDIT_BOOKING: "/editbookings",
    USER_DELETE_BOOKING: "/DeleteBookings",
    USER_MEETROOM_SLOT: "/meetroomslot",
    ADMIN_ADD_ROOM: "/meetrooms",
    ADMIN_FETCH_ROOM: "/fetchroom",
    ADMIN_EDIT_ROOM: "/editroom",
    ADMIN_DELETE_ROOM: "/deleteroom",
  },
  DESK_BOOKING: {
    DESK_WEEK_DATA: "/week",
    DESK_NEXT_WEEK_DATA: "/week?direction=next",
    DESK_PREV_WEEK_DATA: "/week?direction=pre",
    DESK_DATA_WITH_ID: "/desk",
    DESK_BOOKING_FILTER_WITH_PAGE: "/deskbook?filter=",
    DESK_BOOKING_DELETE: "/deskbookdelete",
    DESK_ALL_BOOKINGS_DELETE: "/alldeskbookdelete",
    DESK_BOOK: "/deskbook?notification",
    DESK_FLOORPLAN: "/floorplan",
  },
};
