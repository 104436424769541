import React, { useEffect, useState } from "react";
import {
  DataNotFound,
  Loading,
  Nav,
  RoomDetailsTable,
} from "../../../../components";
import { getMeetingRoomBookingHistory } from "../../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../../utils/constants/errorMessages";
import "./BookingHistory.css";

const BookingHistory = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const getApiData = async () => {
      try {
        const bookingsRes = await getMeetingRoomBookingHistory();
        setUserData(bookingsRes);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };
    getApiData();
  }, []);

  return (
    <>
      <Nav />
      {loading ? (
        <Loading />
      ) : (
        <>
          {userData?.length === 0 ? (
            <DataNotFound error="History Not Found" />
          ) : (
            <div className="history-container">
              <div className="history-heading-box">
                <div className="history-heading">
                  <h1>User Booking History</h1>
                </div>
              </div>

              <RoomDetailsTable userData={userData} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookingHistory;
