import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmationModal({
  isShow,
  isHide,
  className,
  title,
  message,
  footerClassName,
  btn1text,
  btn2text,
  btn1Click,
  btn2Click,
  btn1Variant,
  btn2Variant,
}) {
  return (
    <Modal className={className} show={isShow} onHide={isHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer className={footerClassName}>
        <Button variant={btn1Variant} onClick={btn1Click}>
          {btn1text}
        </Button>
        <Button variant={btn2Variant} onClick={btn2Click}>
          {btn2text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  isHide: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  footerClassName: PropTypes.string,
  btn1text: PropTypes.string.isRequired,
  btn2text: PropTypes.string.isRequired,
  btn1Click: PropTypes.func.isRequired,
  btn2Click: PropTypes.func.isRequired,
  btn1Variant: PropTypes.string.isRequired,
  btn2Variant: PropTypes.string.isRequired,
};
export default ConfirmationModal;
