export const USER_ROLE = {
  USER: "User",
  ADMIN: "Admin",
};

export const STATUS_COLORS = {
  GREEN: "green",
  RED: "#e15b5b",
  BLACK: "black",
};
