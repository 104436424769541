import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MRBS_ADMIN_ROUTES } from "../../../routes/routes";

const ProtectedRoute = ({
  Components,
  isMRBSUser,
  isMRBSAdmin,
  isDBSUser,
  isDBSAdmin,
}) => {
  const navigate = useNavigate();
  const userLogin = MRBS_ADMIN_ROUTES.LOGIN;
  const adminLogin = MRBS_ADMIN_ROUTES.LOGIN;

  /* This `useEffect` hook in the `ProtectedRoute` component is responsible for checking the authentication status of the user based on the provided props (`isMRBSUser`, `isMRBSAdmin`,`isDBSUser`, `isDBSAdmin`) and session storage values.*/
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const isMrbsAdminToken = sessionStorage.getItem("Admin");
    const isDeskAdmin = sessionStorage.getItem("deskad");
    const isUser = sessionStorage.getItem("usr");

    /* This `switch` statement inside the `useEffect` hook of the `ProtectedRoute` component is responsible for determining the appropriate action to take based on the authentication status of the user.*/
    switch (true) {
      case isMRBSUser && !token:
        navigate(userLogin);
        break;
      case isMRBSAdmin && !isMrbsAdminToken:
        navigate(adminLogin);
        break;
      case isDBSUser && (isDeskAdmin || !token):
        navigate(userLogin);
        break;
      case isDBSAdmin && (!isDeskAdmin || isUser):
        navigate(userLogin);
        break;
      default:
        break;
    }
  }, [
    navigate,
    isMRBSUser,
    isMRBSAdmin,
    isDBSUser,
    isDBSAdmin,
    adminLogin,
    userLogin,
  ]);

  return <Components />;
};
ProtectedRoute.propTypes = {
  Components: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isMRBSUser: PropTypes.bool,
  isMRBSAdmin: PropTypes.bool,
  isDBSUser: PropTypes.bool,
  isDBSAdmin: PropTypes.bool,
};
export default ProtectedRoute;
