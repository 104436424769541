import {
  deleteData,
  fetchData,
  patchData,
  patchMultiFormData,
  postData,
  postMultiFormData,
} from "./api.service";
import { API_ENDPOINTS } from "../utils/apiEndpoints";
import { ERROR_MESSAGES } from "../utils/constants/errorMessages";

/* This function `getMeetingRooms` asynchronously fetches meeting rooms data from a specified API endpoint and returning the result of fetching meeting rooms data.*/
export const getMeetingRooms = async (isAdmin = false) => {
  try {
    const url = isAdmin ? API_ENDPOINTS.MRBS.USER_MEETROOMS : API_ENDPOINTS.MRBS.USER_MEETROOMS_ACTIVE;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function retrieves a meeting room by its ID using an API endpoint and returning the result of fetching meeting room data.*/
export const getMeetingRoomById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.ADMIN_FETCH_ROOM}/${id}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `editMeetingRoomById` asynchronously updates a meeting room by sending a PATCH request with multi-form data to the specified API endpoint and returning the response.*/
export const editMeetingRoomById = async (id, initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.ADMIN_EDIT_ROOM}/${id}`;
    const res = await patchMultiFormData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `addNewMeetingRoom` asynchronously adds a new meeting room by sending a POST request with multi-form data to a specified API endpoint ant returning the response from the API call made to add a new meeting room.*/
export const addNewMeetingRoom = async (initialRequestBody) => {
  try {
    const url = API_ENDPOINTS.MRBS.ADMIN_ADD_ROOM;
    const res = await postMultiFormData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `deleteMeetingRoomById` deletes a meeting room by its ID using an API endpoint and returning the response from the API call made to delete a meeting room by its ID.*/
export const deleteMeetingRoomById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.ADMIN_DELETE_ROOM}/${id}`;
    const res = await deleteData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getMeetingRoomHistoryById` retrieves the meeting room history by its ID asynchronously.*/
export const getMeetingRoomHistoryById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.USER_MEETROOMS}/${id}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `bookMeetingRoom` asynchronously sends a POST request to book a meeting room using the initial request body and returns the response data from the API call made to book a meeting room.*/
export const bookMeetingRoom = async (initialRequestBody) => {
  try {
    const url = API_ENDPOINTS.MRBS.USER_BOOK_ROOM;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getMeetingRoomAvailableSlots` sends a POST request to a specified API endpoint to retrieve available slots for a meeting room.*/
export const getMeetingRoomAvailableSlots = async (initialRequestBody) => {
  try {
    const url = API_ENDPOINTS.MRBS.USER_MEETROOM_SLOT;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getAllGuestsList` asynchronously fetches a list of guests from a specified API endpoint and returning the response data.*/
export const getAllGuestsList = async () => {
  try {
    const url = API_ENDPOINTS.COMMON.GUEST_LIST;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getMeetingRoomBookingHistory` asynchronously fetches the booking history of a user from a specified API endpoint and returning the result of fetching meeting room booking history data.*/
export const getMeetingRoomBookingHistory = async () => {
  try {
    const url = API_ENDPOINTS.MRBS.USER_MY_BOOKINGS;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getMeetingRoomActiveBookings` fetches user bookings from a specified API endpoint asynchronously and returning the result of fetching user bookings.*/
export const getMeetingRoomActiveBookings = async () => {
  try {
    const url = API_ENDPOINTS.MRBS.USER_BOOKINGS;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `getMeetingRoomBookingById` fetches a meeting room booking by its ID asynchronously.*/
export const getMeetingRoomBookingById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.USER_BOOKING_WITH_ID}/${id}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `editMeetingRoomBookingById` edits a meeting room booking by sending a PATCH request to the server with the updated booking information and returning the response data.*/
export const editMeetingRoomBookingById = async (id, initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.USER_EDIT_BOOKING}/${id}`;
    const res = await patchData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};

/* This function `deleteMeetingRoomBookingById` deletes a meeting room booking by its ID using an API endpoint.*/
export const deleteMeetingRoomBookingById = async (id, initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.MRBS.USER_DELETE_BOOKING}/${id}`;
    const res = await deleteData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
