import Multiselect from "multiselect-react-dropdown";
import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ImCross } from "react-icons/im";
import { styles } from "../../styleSheet.js";
import "./Form.css";
import PropTypes from "prop-types";

function RoomBookingForm({
  isShow,
  isHide,
  isFormSubmit,
  RoomName,
  crossBtn,
  title,
  handleTitle,
  date,
  handleDateChange,
  startTime,
  handleStartTime,
  endTime,
  handleEndtime,
  suggestions,
  onSelectOptions,
  onRemoveOptions,
  selectedOptions,
  description,
  handleDescription,
  isBooking,
  isPrivate,
  handleCheckbox,
}) {
  useEffect(() => {
    const searchWrapper = document.querySelector(".search-wrapper");
    if (searchWrapper) {
      searchWrapper.scrollTop = searchWrapper.scrollHeight;
    }
  }, [selectedOptions]);
  return (
    <Offcanvas show={isShow} onHide={isHide} className="room-booking-form">
      <Offcanvas.Body style={styles.roomBookingFormBgBlur}>
        <div className="room-booking-form-card">
          <div className="create-room-form-container">
            <Form onSubmit={isFormSubmit}>
              <div className="room-name-heading">
                <h1 className="booking-form-heading">
                Book a Meeting : <span>{RoomName}</span>
                </h1>{" "}
                <button className="cancel-btn" type="button" onClick={crossBtn}>
                  <ImCross />
                </button>
              </div>
              <hr />
              <div className="create-room-form-container">
                {" "}
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    Meeting Title *
                  </Form.Label>
                  <div className="form-input-box">
                    <Form.Control
                      type="text"
                      name="title"
                      value={title}
                      maxLength={100}
                      onChange={handleTitle}
                      required
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    Select Date *
                  </Form.Label>
                  <div className="form-input-box">
                    <Form.Control
                      type="date"
                      name="date"
                      value={date}
                      onChange={handleDateChange}
                      min={new Date().toISOString().split("T")[0]}
                      required
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    Start Time *
                  </Form.Label>
                  <div className="form-input-box">
                    <Form.Control
                      type="time"
                      name="startTime"
                      value={startTime}
                      onChange={handleStartTime}
                      required
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    End Time *
                  </Form.Label>
                  <div className="form-input-box">
                    <Form.Control
                      type="time"
                      name="endTime"
                      value={endTime}
                      onChange={handleEndtime}
                      required
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    Add guests
                  </Form.Label>
                  <div className="form-input-box multi-select-container">
                    <Multiselect
                      options={suggestions}
                      name="particulars"
                      onSelect={onSelectOptions}
                      onRemove={onRemoveOptions}
                      displayValue="guestName"
                      closeIcon="cancel"
                      placeholder="Select Guests"
                      selectedValues={selectedOptions}
                      className="multiSelectContainer"
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <Form.Label className="form-label-text">
                    Description of Meeting
                  </Form.Label>
                  <div className="form-input-box">
                    <Form.Control
                      type="text"
                      value={description}
                      onChange={handleDescription}
                      as="textarea"
                      row={5}
                    />
                  </div>
                </div>
                <div className="create-room-form-box">
                  <div className="form-input-box">
                    <Form.Check
                      type="checkbox"
                      id={`private-meeting`}
                      label={`Private Meeting`}
                      checked={isPrivate}
                      onChange={handleCheckbox}
                    />
                  </div>
                </div>
              </div>
              <div className="booking-form-save-btn">
                <button
                  type="submit"
                  className={isBooking ? "disabled" : ""}
                  disabled={isBooking}
                >
                  {" "}
                  {isBooking ? "Loading..." : "Book"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
RoomBookingForm.propTypes = {
  isShow: PropTypes.bool.isRequired,
  isHide: PropTypes.func.isRequired,
  isFormSubmit: PropTypes.func.isRequired,
  RoomName: PropTypes.string.isRequired,
  crossBtn: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleTitle: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
  handleStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.string.isRequired,
  handleEndtime: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  onSelectOptions: PropTypes.func,
  onRemoveOptions: PropTypes.func,
  selectedOptions: PropTypes.array.isRequired,
  description: PropTypes.string,
  handleDescription: PropTypes.func,
  isBooking: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  handleCheckbox: PropTypes.func.isRequired
};

export default RoomBookingForm;
