import { React, useEffect, useState } from "react";
import { getMeetingRooms } from "../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages";
import { Loading, Pagination } from "../../index";
import "./RoomCard.css";

const DashboardRoomCards = () => {
  const [loading, setLoading] = useState(true);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = meetingRooms?.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const getApiData = async () => {
      try {
        const meetRoomsRes = await getMeetingRooms();
        setMeetingRooms(meetRoomsRes);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };

    getApiData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="room-cards-container">
            {currentItems?.map((item) => (
              <div className="room-card-box" key={item?.id}>
                <img
                  className="meeting-room-img"
                  src={item?.imageUrl}
                  alt="meeting-room"
                />
                <div className="meeting-room-details">
                  <h1 className="room-card-name">{item?.meetRoomName}</h1>
                  <p>
                    Capacity: <span>{item?.capacity}</span> People
                  </p>
                </div>
              </div>
            ))}
          </div>
          {meetingRooms?.length > 8 && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={meetingRooms.length}
              itemsPerPage={itemsPerPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default DashboardRoomCards;
