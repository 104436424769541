import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardRoomCards, Hero, Nav } from "../../../../components";
import { USER_ROLE } from "../../../../utils/constants/constants";
const UserDashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      sessionStorage.setItem("token", JSON.stringify(token));
    }
  }, [location.search]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      sessionStorage.setItem("userId", decodedToken.jwtData.id);
      sessionStorage.setItem("userName", decodedToken.jwtData.userName);
      sessionStorage.setItem("lastname", decodedToken.jwtData.last_name);
      const isAdmin = decodedToken.jwtData.role === USER_ROLE.ADMIN;
      if (isAdmin) {
        sessionStorage.setItem("deskad", decodedToken.jwtData.role);
        setIsAdmin(true);
      } else {
        sessionStorage.setItem("usr", decodedToken.jwtData.role);
      }
    }
  }, [navigate]);

  return (
    <>
      <Nav />
      <Hero isAdmin={isAdmin} />
      <div className="allroom">
        <h1>Available Meeting Rooms</h1>
      </div>
      <DashboardRoomCards />
    </>
  );
};

export default UserDashboard;
