import { API_ENDPOINTS } from "../utils/apiEndpoints";
import { ERROR_MESSAGES } from "../utils/constants/errorMessages";
import {
  deleteData,
  fetchData,
  patchData,
  patchMultiFormData,
  postData,
  postDeskData,
  postMultiFormData,
} from "./api.service";

export const getDeskById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_DATA_WITH_ID}/${id}`;
    const deskFetchRes = await fetchData(url);
    return deskFetchRes;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const addNewDesk = async (initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_DATA_WITH_ID}`;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.postDataError, error);
    throw error;
  }
};
export const editDeskById = async (id, initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_DATA_WITH_ID}/${id}`;
    const res = await patchData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.patchDataError, error);
    throw error;
  }
};
export const deleteDeskById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_DATA_WITH_ID}/${id}`;
    const res = await deleteData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.deleteDataError, error);
    throw error;
  }
};
export const bookDesk = async (initialRequestBody, isChecked = false) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_BOOK}${
      isChecked ? "=on" : "=off"
    }`;
    const res = await postDeskData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.patchDataError, error);
    throw error;
  }
};
export const getDeskBookingsHistory = async (
  bookingType,
  currentPageTodays,
  itemsPerPage
) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_BOOKING_FILTER_WITH_PAGE}${bookingType}&pageno=${currentPageTodays}&perpage=${itemsPerPage}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const deleteDeskBooking = async (initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_BOOKING_DELETE}`;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.deleteDataError, error);
    throw error;
  }
};
export const deleteAllBookings = async () => {
  try {
    const url = API_ENDPOINTS.DESK_BOOKING.DESK_ALL_BOOKINGS_DELETE;
    await deleteData(url);
  } catch (error) {
    console.error(ERROR_MESSAGES.deleteDataError, error);
    throw error;
  }
};
export const getCurrentWeekBookings = async () => {
  try {
    const url = API_ENDPOINTS.DESK_BOOKING.DESK_WEEK_DATA;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const getNextWeekBookings = async (initialRequestBody) => {
  try {
    const url = API_ENDPOINTS.DESK_BOOKING.DESK_NEXT_WEEK_DATA;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.postDataError, error);
    throw error;
  }
};
export const getPrevWeekBookings = async (initialRequestBody) => {
  try {
    const url = API_ENDPOINTS.DESK_BOOKING.DESK_PREV_WEEK_DATA;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.postDataError, error);
    throw error;
  }
};
export const getFloorPlans = async () => {
  try {
    const url = API_ENDPOINTS.DESK_BOOKING.DESK_FLOORPLAN;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const getFloorPlanById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_FLOORPLAN}/${id}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const addNewFloorPlan = async (initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_FLOORPLAN}`;
    const res = await postMultiFormData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const editFloorPlanById = async (id, initialRequestBody) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_FLOORPLAN}/${id}`;
    const res = await patchMultiFormData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
export const deleteFloorPlanById = async (id) => {
  try {
    const url = `${API_ENDPOINTS.DESK_BOOKING.DESK_FLOORPLAN}/${id}`;
    const res = await deleteData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
