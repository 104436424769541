export const COMMON_ROUTES = {
  LOGIN: "/",
  PAGE_NOT_FOUND: "/*",
};
export const MRBS_USER_ROUTES = {
  LOGIN: "/login",
  HOME: "/home",
  BOOKING: "/booking",
  HISTORY: "/history",
};

export const MRBS_ADMIN_ROUTES = {
  LOGIN: "/admin/login",
  ALL_ROOMS: "/admin/all-rooms",
  ADD_ROOMS: "/admin/add-rooms",
  ROOM_DETAILS: "/admin/meetrooms/:id",
  ROOM_DETAILS_PAGE: "/admin/meetrooms",
};

export const DBS_USER_ROUTES = {
  DASHBOARD: "/deskbooking",
  BOOKINGS: "/bookings",
};

export const DBS_ADMIN_ROUTES = {
  DASHBOARD: "/admin/deskbooking",
  BOOKINGS: "/admin/bookings",
};
