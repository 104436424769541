const selectWeekDaysBtn = (selectedDays, day, isDisabled) => ({
  background: selectedDays?.includes(day) ? "#66c2ff" : "#f2f2f2",
  pointerEvents: isDisabled(day) ? "none" : "auto",
  opacity: isDisabled(day) ? 0.5 : 1,
  margin: "2px",
  padding: "4px",
  height: "2.5rem",
  width: "2rem",
});
const guestListMenuItoms = {
  PaperProps: {
    style: {
      maxHeight: 8 * 48,
      width: 250,
      overflowY: "auto",
    },
  },
};
const guestAvtarBgColor = (backgroundColors, getColorIndex, user) => ({
  backgroundColor:
    backgroundColors[getColorIndex(user?.guestName[0]?.toUpperCase())],
});
const deskUserAvtarBgColor = (backgroundColors, index) => ({
  backgroundColor: backgroundColors[index % backgroundColors?.length],
});
const historyPaginationUpPreBtn = (currentPageUpcoming) => ({
  opacity: currentPageUpcoming === 1 ? 0.7 : 1,
});
const historyPaginationUpNxtBtn = (
  upcomingBookings,
  itemsPerPage,
  lastUpcomingBooking
) => ({
  opacity:
    upcomingBookings?.length < itemsPerPage || lastUpcomingBooking ? 0.7 : 1,
});
const historyPaginationPastPreBtn = (currentPagePast) => ({
  opacity: currentPagePast === 1 ? 0.7 : 1,
});
const historyPaginationPastNxtBtn = (
  pastBookings,
  itemsPerPage,
  lastPastBooking
) => ({
  opacity: pastBookings?.length < itemsPerPage || lastPastBooking ? 0.7 : 1,
});
const isDeskAdminCursor = (isAdmin) => ({
  cursor: isAdmin && "pointer",
});

const modalViewFloorPlansImg = {
  height: "600px",
  width: "100%",
  textAlign: "center",
  marginBottom: "10px",
};
const viewFloorPlansImgContainer = {
  textAlign: "center",
  marginBottom: "10px",
};
const imageNotFoundText = { margin: "0 8rem" };
const viewFloorPlansImg = {
  objectFit: "contain",
  margin: "auto",
  display: "block",
  transition: "transform 0.25s ease-in-out",
  height: "80%",
  width: "100%",
};
const editFloorPlanBtn = {
  marginBottom: "10px",
  marginTop: "10px",
  position: "absolute",
  bottom: 10,
  right: 20,
};
const uploadingBtn = (isUploading) => ({ opacity: isUploading ? 0.5 : 1 });
const hoverCursor = { cursor: "pointer" };
const addDeskBtn = (isAdding, deskNumber) => ({
  opacity: isAdding || !deskNumber ? 0.5 : 1,
});
const deleteDeskBtn = (isDeleting) => ({
  opacity: isDeleting && 0.5,
});
const userCharBgColor = (backgroundColors, getColorIndex, selected) => ({
  backgroundColor: backgroundColors[getColorIndex(selected[0].toUpperCase())],
});
const deskValidDuration = (isValidDuration) => ({
  borderColor: !isValidDuration && "red",
});
const deskOwnerBgColor = (backgroundColors, getColorIndex, DeskOwner) => ({
  backgroundColor:
    backgroundColors[getColorIndex(DeskOwner?.DeskOwnerName[0]?.toUpperCase())],
});
const deskUserBgColor = (backgroundColors, getColorIndex, user) => ({
  backgroundColor:
    backgroundColors[
      getColorIndex(user?.DeskUser?.DeskUserName[0]?.toUpperCase())
    ],
});
export const styles = {
  selectWeekDaysBtn,
  guestListMenuItoms,
  guestAvtarBgColor,
  deskUserAvtarBgColor,
  historyPaginationUpPreBtn,
  historyPaginationUpNxtBtn,
  historyPaginationPastPreBtn,
  historyPaginationPastNxtBtn,
  isDeskAdminCursor,
  viewFloorPlansImg,
  viewFloorPlansImgContainer,
  imageNotFoundText,
  modalViewFloorPlansImg,
  editFloorPlanBtn,
  hoverCursor,
  uploadingBtn,
  addDeskBtn,
  deleteDeskBtn,
  userCharBgColor,
  deskValidDuration,
  deskOwnerBgColor,
  deskUserBgColor,
};
