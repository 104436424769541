import { Avatar } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { GuestListContext } from "../../context/GuestListContext";
import {
  deleteAllBookings,
  deleteDeskBooking,
  getCurrentWeekBookings,
  getNextWeekBookings,
  getPrevWeekBookings,
} from "../../services/deskBooking.service";
import { ERROR_MESSAGES } from "../../utils/constants/errorMessages";
import {
  DESK_BOOKING_MESSAGES,
  MODAL_TYPE,
} from "../../utils/constants/messages";
import {
  backgroundColors,
  getColorIndex,
  getSaturdayOfPreviousWeek,
  isDateInPast,
  isInCurrentWeek,
} from "../../utils/helpers";
import {
  AddNewDesk,
  ConfirmationModal,
  DataNotFound,
  DeskBookingComponent,
  DeskBookingTableHeader,
  Loading,
} from "../index";
import "./DeskBookingTable.css";
import { styles } from "./styleSheet";
function DeskBookingTable({
  isAdmin,
  currentWeekData,
  currentWeekLoading,
  currentWeekNoData,
}) {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [bookingInfo, setBookingInfo] = useState(null);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [editDeskPopup, setEditDeskPopup] = useState(false);
  const [deskNamePop, setDeskNamePop] = useState({});
  const [popupData, setPopupData] = useState({
    date: "",
    deskName: "",
    deskId: "",
  });
  const guestList = useContext(GuestListContext);
  const storedUsername = sessionStorage.getItem("userName");
  const storedLastname = sessionStorage.getItem("lastname");
  const ticket = Number(sessionStorage.getItem("userId"));

  /* This `useEffect` hook update the state variables `data`,`loading`, and `noData` based on the values of `currentWeekData` `currentWeekLoading`, and `currentWeekNoData` */

  useEffect(() => {
    setData(currentWeekData);
    setLoading(currentWeekLoading);
    setNoData(currentWeekNoData);
  }, [currentWeekData, currentWeekLoading, currentWeekNoData]);

  /* This useEffect to set the user state to the value of the guestList array whenever the guestList array changes.*/
  useEffect(() => {
    setUser(guestList);
  }, [guestList]);

  /* This `useEffect` concatenate the `storedUsername` and `storedLastname` variables and store the result in the `selectedUser` state variable.*/
  useEffect(() => {
    const concatenatedName = `${storedUsername} ${storedLastname}`;
    setSelectedUser(concatenatedName);
  }, [storedLastname, storedUsername]);

  /* The function `handleCurrentWeekData` fetches current week desk data from an API endpoint for desk bookings and updates the state accordingly.*/
  const handleCurrentWeekData = async () => {
    try {
      const currentWeekBookings = await getCurrentWeekBookings();
      setLoading(false);
      setData(currentWeekBookings);
    } catch (error) {
      setLoading(false);
      setNoData(true);
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  };

  /* This `useEffect` hook run a function `handleCurrentWeekData` when the `isAdmin` variable changes. If `isAdmin` is false, the `handleCurrentWeekData` function will be called. */
  useEffect(() => {
    if (!isAdmin) {
      handleCurrentWeekData();
    }
  }, [isAdmin]);

  /* The function `handleNextWeekData` handle fetching data for the next weeks*/
  const handleNextWeekData = async () => {
    try {
      const lastDate = data[data?.length - 1]?.date;
      const initialRequestBody = { date: lastDate };
      const nextWeekBookings = await getNextWeekBookings(initialRequestBody);
      setLoading(false);
      setData(nextWeekBookings);
    } catch (error) {
      setLoading(false);
      setNoData(true);
      console.error(ERROR_MESSAGES.postDataError, error);
    }
  };

  /* The function `handlePrevWeekData` handle fetching data for the previous weeks*/
  const handlePrevWeekData = async () => {
    try {
      const firstDate = data[0]?.date;
      const initialRequestBody = { date: firstDate };
      const prevWeekData = await getPrevWeekBookings(initialRequestBody);
      setLoading(false);
      setData(prevWeekData);
    } catch (error) {
      setLoading(false);
      setNoData(true);
      console.error(ERROR_MESSAGES.postDataError, error);
    }
  };

  /* The function `handleBookingNextWeek` fetches data for the next week based on a given date and updates the UI accordingly.*/
  const handleBookingNextWeek = async (date) => {
    try {
      const initialRequestBody = { date: date };
      const nextWeekBookings = await getNextWeekBookings(initialRequestBody);
      setData(nextWeekBookings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setNoData(true);
      console.error(ERROR_MESSAGES.postDataError, error);
    }
  };

  /* The function `handleDeleteThisBooking` deletes a booking if the date is not in the past*/
  const handleDeleteThisBooking = async (info) => {
    if (!isDateInPast(info?.date)) {
      try {
        const initialRequestBody = {
          DeskName: info?.deskName,
          date: info?.date,
        };
        const deletedDesk = await deleteDeskBooking(initialRequestBody);
        handleClose();
        if (isInCurrentWeek(deletedDesk?.date)) {
          handleCurrentWeekData();
        } else {
          handleNextWeekBooking(deletedDesk?.date);
        }
      } catch (error) {
        console.error(ERROR_MESSAGES.deleteDataError, error);
      }
    }
  };

  /* The function `handleDeleteAllBookings` deletes all bookings for a specific date if it is not in the past.*/
  const handleDeleteAllBookings = async (info) => {
    if (!isDateInPast(info?.date)) {
      try {
        await deleteAllBookings();
        handleClose();
        if (isInCurrentWeek(info?.date)) {
          handleCurrentWeekData();
        } else {
          handleNextWeekBooking(info?.date);
        }
      } catch (error) {
        console.error(ERROR_MESSAGES.deleteDataError, error);
      }
    }
  };

  /* The `handleOpen` function checks if a given date is in the past and sets popup data accordingly.*/
  const handleOpen = (userData) => {
    if (!isDateInPast(userData?.date)) {
      setPopupData({
        date: userData?.date,
        deskName: userData?.deskName,
        deskId: userData?.deskId,
        day: userData?.day,
      });
      setShowModal(true);
    }
  };

  /* The function `handleNextWeekBooking` takes a date as input, calculates the last Saturday of the previous week using the `getSaturdayOfPreviousWeek` function, and then handles booking for the next week based on that date.*/
  const handleNextWeekBooking = (date) => {
    const lastDate = getSaturdayOfPreviousWeek(date);
    handleBookingNextWeek(lastDate);
  };

  /* The function `handleDeleteShow` checks if the user is authorized to delete a booking and either displays a confirmation dialog or directly deletes the booking.*/
  const handleDeleteShow = (dayData, user) => {
    const concatenatedName = `${storedUsername} ${storedLastname}`;
    const info = {
      date: dayData,
      deskName: user?.DeskName,
    };
    if (
      user?.DeskUser?.DeskUserName === concatenatedName &&
      user?.repeatOption !== "null"
    ) {
      setBookingInfo(info);
      setShow(true);
    } else {
      setBookingInfo(info);
      handleDeleteThisBooking(info);
    }
  };

  const handleOpenEditDesk = (DeskData) => {
    setDeskNamePop(DeskData);
    setEditDeskPopup(true);
  };

  const handleCloseEditDesk = () => {
    setEditDeskPopup(false);
  };

  const handleClose = () => setShow(false);

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  /* The function `handleAvatarHover` checks if the user's `UserId` matches a `ticket` and sets a hover effect accordingly.*/
  const handleAvatarHover = (user) => {
    const concatenatedName = `${storedUsername} ${storedLastname}`;
    if (user?.DeskUser) {
      const userId = user?.UserId;
      if (userId === ticket) {
        setHoverEffect(true);
      } else if (user?.DeskUser?.DeskUserName === concatenatedName) {
        setHoverEffect(true);
      } else {
        setHoverEffect(false);
      }
    }
  };

  /* The function `handleMouseOut` removes the "avatar-hover" class from the element with the class "avatar-container" when the mouse moves out of it.*/
  const handleMouseOut = () => {
    const avatarContainer = document.querySelector(".avatar-container");
    if (avatarContainer) {
      avatarContainer.classList.remove("avatar-hover");
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (noData) {
    return <DataNotFound error="Desks Not Found" />;
  }
  return (
    <div className="desk-booking-table-container">
      <table className="table">
        <DeskBookingTableHeader
          dates={data}
          handleNextData={handleNextWeekData}
          handlePrevData={handlePrevWeekData}
        />
        <tbody>
          {data &&
            data?.length > 0 &&
            data[0]?.desks &&
            data[0]?.desks.length > 0 &&
            data[0]?.desks.map((desk, index) => (
              <tr key={desk?.Id}>
                <td>
                  <button
                    className="tesk-table-row"
                    style={styles.isDeskAdminCursor(isAdmin)}
                    onClick={
                      isAdmin
                        ? () =>
                            handleOpenEditDesk({
                              Id: desk?.Id,
                              deskName: desk?.DeskName,
                              deskNumber: index + 1,
                              deskType: desk?.DeskType,
                            })
                        : undefined
                    }
                  >
                    <Avatar
                      alt={desk?.DeskName}
                      src={desk?.logo}
                      className="desk-name-btn"
                      style={styles.deskUserAvtarBgColor(
                        backgroundColors,
                        index
                      )}
                    >
                      {desk?.DeskName[0]?.toUpperCase()}
                    </Avatar>
                    <div className="desk-name-conatainer">
                      <div className="desk-name-and-type-box">
                        <span className="desk-name-style">
                          {desk?.DeskName}
                        </span>{" "}
                        <span className="desk-type-style">
                          {desk?.DeskType === "Dedicated Desk"
                            ? "Dedicated"
                            : "Hot Desk"}
                        </span>
                      </div>
                      <div className="desk-sr-no">{index + 1}</div>
                    </div>
                  </button>
                </td>
                {data?.map((dayData) => {
                  const matchingDesk = dayData?.desks?.find(
                    (d) => d?.DeskName === desk?.DeskName
                  );
                  const isDeskDedicated =
                    matchingDesk && matchingDesk?.DeskType === "Dedicated Desk";
                  return (
                    <td key={`${dayData?.date}-${desk?.DeskName}`}>
                      {isDeskDedicated ? (
                        <>
                          {matchingDesk?.DeskOwner?.DeskOwnerName ? (
                            <div
                              className="disable-tesk-row1 matching-desk-box"
                              key={`${dayData?.date}-${desk?.DeskName}-${matchingDesk?.DeskOwner?.DeskOwnerName}`}
                            >
                              <div className="userdetails disable">
                                <div className="usernameStyle">
                                  <p>
                                    {matchingDesk?.DeskOwner?.DeskOwnerName}
                                  </p>
                                </div>
                                <div className={"avatar-container"}>
                                  <Avatar
                                    className="desk-user-avatar"
                                    src={
                                      matchingDesk?.DeskOwner?.DeskOwnerEmail
                                    }
                                    style={styles.deskOwnerBgColor(
                                      backgroundColors,
                                      getColorIndex,
                                      matchingDesk?.DeskOwner
                                    )}
                                  >
                                    {matchingDesk?.DeskOwner?.DeskOwnerName[0]?.toUpperCase()}
                                  </Avatar>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="disable-book">
                              <span className="disable">
                                <p className="booknameStyle">Unavailable</p>
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {matchingDesk?.DeskUser?.length > 0 ? (
                            matchingDesk?.DeskUser?.map((user) => (
                              <div
                                className={
                                  isDateInPast(dayData?.date)
                                    ? "disable-tesk-row1"
                                    : "tesk-row1"
                                }
                                key={`${dayData?.date}-${desk?.DeskName}-${user?.DeskUserName}`}
                                style={styles.hoverCursor}
                              >
                                <div
                                  className={`userdetails ${
                                    isDateInPast(dayData?.date) && "disable"
                                  }
                              `}
                                >
                                  <div className="usernameStyle">
                                    <p>{user?.DeskUser?.DeskUserName}</p>
                                  </div>
                                  <div
                                    className={`${
                                      hoverEffect ? "avatar-container" : ""
                                    }`}
                                    onMouseOver={() => {
                                      if (!isDateInPast(dayData?.date)) {
                                        handleAvatarHover(user);
                                      }
                                    }}
                                    onMouseOut={() => {
                                      if (!isDateInPast(dayData?.date)) {
                                        handleMouseOut();
                                      }
                                    }}
                                  >
                                    {" "}
                                    <Avatar
                                      className="desk-user-avatar"
                                      src={user?.DeskUserEmail}
                                      style={styles.deskUserBgColor(
                                        backgroundColors,
                                        getColorIndex,
                                        user
                                      )}
                                    >
                                      {user?.DeskUser?.DeskUserName
                                        ? user?.DeskUser?.DeskUserName[0]?.toUpperCase()
                                        : ""}
                                    </Avatar>
                                    <div
                                      className={
                                        isDateInPast(dayData?.date)
                                          ? "disable close-icon"
                                          : "close-icon"
                                      }
                                      onClick={() => {
                                        if (!isDateInPast(dayData?.date)) {
                                          handleDeleteShow(dayData?.date, user);
                                        }
                                      }}
                                      disabled={isDateInPast(dayData?.date)}
                                    >
                                      &#10006;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className={
                                isDateInPast(dayData?.date)
                                  ? "disable-book"
                                  : "book"
                              }
                            >
                              <span
                                className={
                                  isDateInPast(dayData?.date)
                                    ? "disable table-book-text"
                                    : "table-book-text"
                                }
                                onClick={() =>
                                  handleOpen({
                                    date: dayData?.date,
                                    deskName: desk?.DeskName,
                                    deskId: desk?.Id,
                                    day: dayData?.day,
                                    id: null,
                                  })
                                }
                                disabled={isDateInPast(dayData?.date)}
                              >
                                <p className="booknameStyle">
                                  Book{"    "}
                                  <RiArrowDropDownLine size={30} />
                                </p>
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
                <td>
                  {" "}
                  <div className="empty-row"></div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <DeskBookingComponent
        showModal={showModal}
        handleModalClose={handleModalClose}
        popupData={popupData}
        handleChange={handleChange}
        selectedUser={selectedUser}
        users={user}
        reset={handleCurrentWeekData}
        handleBookingNextWeek={handleBookingNextWeek}
      />{" "}
      <AddNewDesk
        users={user}
        reset={handleCurrentWeekData}
        isEdit={true}
        deskData={deskNamePop}
        isOpen={editDeskPopup}
        isClose={handleCloseEditDesk}
      />
      <ConfirmationModal
        isShow={show}
        isHide={handleClose}
        className="delete-booking-modal"
        title={MODAL_TYPE.CONFIRMATION}
        message={DESK_BOOKING_MESSAGES.DELETE_ALL_BOOKINGS_CONFIRMATION}
        footerClassName="delete-booking-footer"
        btn1text="This Booking"
        btn2text="All Bookings"
        btn1Click={() => handleDeleteThisBooking(bookingInfo)}
        btn2Click={() => handleDeleteAllBookings(bookingInfo)}
        btn1Variant={MODAL_TYPE.SUCCESS}
        btn2Variant={MODAL_TYPE.DANGER}
      />
    </div>
  );
}

DeskBookingTable.propTypes = {
  isAdmin: PropTypes.bool,
  currentWeekData: PropTypes.array,
  currentWeekLoading: PropTypes.bool,
  currentWeekNoData: PropTypes.bool,
};
export default DeskBookingTable;
