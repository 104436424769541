import React from "react";
import { Modal } from "react-bootstrap";
import alertImage from "../../assets/images/alertImage.png";
import successImage from "../../assets/images/sucessImage.png";
import { DESK_BOOKING_MESSAGES } from "../../utils/constants/messages";
import "./DeskBookingTable.css";
import PropTypes from "prop-types";

export default function DeskBookingModal({
  showModal,
  handleModalClose,
  message,
  bookPerson,
  isNewDesk,
  isUploadFloorPlanImage,
  isEdit,
  bookedDeskData,
}) {
  let modalImage = null;
  let modalMessage = null;

  /* This `switch` statement is checking the `message` received as a prop against the keys in the`DESK_BOOKING_MESSAGES.success` and `DESK_BOOKING_MESSAGES.error` objects */
  switch (true) {
    case DESK_BOOKING_MESSAGES.success.hasOwnProperty(message):
      modalMessage = DESK_BOOKING_MESSAGES.success[message];
      modalImage = (
        <img src={successImage} alt="Success" className="modalImage" />
      );
      break;
    case DESK_BOOKING_MESSAGES.error.hasOwnProperty(message):
      modalMessage = DESK_BOOKING_MESSAGES.error[message];
      modalImage = <img src={alertImage} alt="Error" className="modalImage" />;
      break;
    default:
      modalMessage = message;
      break;
  }

  return (
    <Modal className="modalComponent" show={showModal} centered>
      <Modal.Header>
        <div className="text-center">
          {modalImage}
          <h5>{modalMessage}</h5>
        </div>
      </Modal.Header>
      {!isUploadFloorPlanImage && (
        <Modal.Body>
          <div className="main">
            <div className="booktitle modal-desk-title">
              {/* <h6>
                {!isNewDesk && !isUploadFloorPlanImage && !isEdit && (
                  <b>Date: {bookPerson?.date}</b>
                )}
              </h6> */}
              <h6>
                <b>Desk Name: {bookPerson?.DeskName || bookPerson?.deskName}</b>
              </h6>
              {/* {bookedDeskData?.length > 0 &&
                message ===
                  DESK_BOOKING_MESSAGES.DESK_BOOKING_COMPLETED_SUCCESFULLY && (
                  <div>
                    <h6>
                      <b>Desks already reserved for:</b>
                    </h6>{" "}
                    {bookedDeskData?.map((date) => (
                      <ul key={date} className="desk-data-map">
                        <li>{date}</li>
                      </ul>
                    ))}
                  </div>
                )} */}
            </div>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary booktitle booking-modal-btn"
          onClick={handleModalClose}
        >
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}

DeskBookingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  bookPerson: PropTypes.array,
  isNewDesk: PropTypes.bool,
  isUploadFloorPlanImage: PropTypes.bool,
  isEdit: PropTypes.bool,
  bookedDeskData: PropTypes.array,
};
