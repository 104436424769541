import React, { useEffect } from "react";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { AdminRoomCard, Nav } from "../../../../components";
import { MRBS_ADMIN_ROUTES } from "../../../../routes/routes";
import "../AdminPages.css";

const AllRooms = () => {
  const location = useLocation();
  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      sessionStorage.setItem("Admin", JSON.stringify(token));
    }
  }, [location.search]);

  return (
    <>
      <Nav isMRBSAdmin={true} />
      <div className="allroom">
        <h1>All Meeting Rooms</h1>
        <Link to={MRBS_ADMIN_ROUTES.ADD_ROOMS} className="me-2 all-rooms-link">
          <span>
            <HiOutlineViewGridAdd />
          </span>{" "}
          Add Rooms
        </Link>
      </div>
      <AdminRoomCard />
    </>
  );
};

export default AllRooms;
