import React, { createContext, useEffect, useState } from "react";
import { getAllGuestsList } from "../services/mrbs.service.js";
import { ERROR_MESSAGES } from "../utils/constants/errorMessages";
export const GuestListContext = createContext();

const GuestListProvider = ({ children }) => {
  const [guestList, setGuestList] = useState([]);

  useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const getGuestList = async () => {
      try {
        const list = await getAllGuestsList();
        setGuestList(list);
      } catch (error) {
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };
    if (token) {
      getGuestList();
    }
  }, []);

  return (
    <GuestListContext.Provider value={guestList}>
      {children}
    </GuestListContext.Provider>
  );
};
export default GuestListProvider;
