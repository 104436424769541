import {
  Avatar,
  FormControl,
  ListItemAvatar,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import { DeskBookingModal } from "../../components/index";
import { DBS_ADMIN_ROUTES } from "../../routes/routes";
import {
  addNewDesk,
  deleteDeskById,
  editDeskById,
  getDeskById,
} from "../../services/deskBooking.service";
import { ERROR_MESSAGES } from "../../utils/constants/errorMessages";
import {
  DESK_BOOKING_MESSAGES,
  DESK_TYPE,
  MODAL_TYPE,
} from "../../utils/constants/messages";
import {
  backgroundColors,
  getColorIndex,
  isValidDeskNumber,
} from "../../utils/helpers";
import "./DeskBookingTable.css";
import { styles } from "./styleSheet";

const AddNewDesk = ({ isOpen, isClose, deskData, isEdit, reset, users }) => {
  const [deskNumber, setDeskNumber] = useState("");
  const [deskType, setDeskType] = useState("");
  const [deskArea, setDeskArea] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [bookDesk, setBookDesk] = useState([]);
  const [deskDelete, setDeskDelete] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const deskNameInputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const user = users?.find((user) => user.guestName === selectedUser);
    if (user) {
      const userData = {
        DeskOwnerName: user?.guestName,
        DeskOwnerEmail: user?.guestEmail,
      };
      setSelectedUserData(userData);
    } else {
      setSelectedUserData({});
    }
  }, [selectedUser, users]);

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  /* This `useEffect` hook is responsible for setting up initial values for the desk type, desk area,and focusing on the desk name input field when the `isOpen` prop changes.*/
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setDeskType(DESK_TYPE.HOTDESK);
    setDeskArea(DESK_TYPE.GENERAL_AREA);
    setDeskNumber("");
    setError("");
    if (!isEdit && deskNameInputRef?.current) {
      deskNameInputRef?.current.focus();
    }
  }, [isOpen, isEdit]);

  /* This `useEffect` hook is responsible for fetching desk data based on certain conditions and updating desk information accordingly.*/
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const fetchDeskData = async () => {
      try {
        const deskFetchRes = await getDeskById(deskData?.Id);
        if (deskFetchRes && deskFetchRes.length > 0) {
          setDeskNumber(deskFetchRes[0]?.DeskName.split(" ")[1]);
          setDeskType(deskFetchRes[0]?.DeskType);
          setDeskArea(deskFetchRes[0]?.DeskArea);
          setSelectedUserData(deskFetchRes[0]?.DeskOwner);
          setSelectedUser(deskFetchRes[0]?.DeskOwner?.DeskOwnerName);
        }
      } catch (error) {
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };
    if (isEdit) {
      setError("");
      fetchDeskData();
    }
  }, [isOpen, isEdit, deskData]);

  /* The isValidDeskNumber function checks if a given value is in the format "Desk" followed by up to 3 digits. */

  /* The function `handleDeskNameChange` extracts the desk number from the input value, and sets the desk number state if it is valid. */
  const handleDeskNameChange = (event) => {
    setError("");
    const { value } = event.target;
    if (isValidDeskNumber(value)) {
      const deskNumber = value?.split(" ")[1];
      setDeskNumber(deskNumber);
    }
  };

  /* The function `handleAddNewDesk` is responsible for adding or editing a desk with the provided details and handling success or error messages accordingly.*/
  const handleAddNewDesk = async (e) => {
    e.preventDefault();
    try {
      setIsAdding(true);
      const initialRequestBody = {
        DeskName: `Desk ${deskNumber}`,
        DeskOwner: selectedUserData,
        DeskType: deskType,
        DeskArea: deskArea,
        Status: "Active",
      };

      let addNewDeskRes;
      if (isEdit) {
        addNewDeskRes = await editDeskById(deskData?.Id, initialRequestBody);
      } else {
        addNewDeskRes = await addNewDesk(initialRequestBody);
      }
      if (addNewDeskRes) {
        setConfirmModal(true);
        if (isEdit) {
          setMessage(DESK_BOOKING_MESSAGES.DESK_EDITED_SUCCESFULLY);
          setBookDesk({ deskName: addNewDeskRes[0]?.DeskName });
        } else {
          setMessage(DESK_BOOKING_MESSAGES.DESK_ADDED_SUCCESFULLY);
          setBookDesk({ deskName: addNewDeskRes?.DeskName });
        }
        isClose();
        if (location.pathname === DBS_ADMIN_ROUTES.DASHBOARD) {
          reset();
        }
      }
      setIsAdding(false);
    } catch (error) {
      setError(
        error.response?.data?.message || ERROR_MESSAGES.somethingWrongError
      );
      setIsAdding(false);
    }
  };

  /* The `confirmDeleteDesk` function deletes a desk using an API call and displays a success or error message in a modal.*/
  const confirmDeleteDesk = async () => {
    try {
      setIsDeleting(true);
      const res = await deleteDeskById(deskData?.Id);
      isClose();
      setConfirmModal(true);
      setMessage(DESK_BOOKING_MESSAGES.DESK_DELETED_SUCCESFULLY);
      setBookDesk({ deskName: res[0]?.DeskName });
      setIsDeleting(false);
      reset();
    } catch (error) {
      setConfirmModal(true);
      setMessage(DESK_BOOKING_MESSAGES.DESK_NOT_DELETED);
      setIsDeleting(false);
      isClose();
      console.error(ERROR_MESSAGES.deleteDataError, error);
    }
  };
  const handleConfirModalClose = () => {
    setConfirmModal(false);
  };
  const handleCloseAddNewDesk = () => {
    isClose();
    setError("");
    setSelectedUser("");
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleCloseAddNewDesk} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? (
              <div className="add-desk-container">
                <div className="booking-form-heading desk-booking-form-heading">
                  {deskData?.deskName}
                </div>
              </div>
            ) : (
              <h1 className="booking-form-heading">Add a New Desk</h1>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddNewDesk}>
            {deskData?.deskType === "Dedicated Desk" && (
              <div className="form-element-container">
                <Form.Label className="form-title-lable">Desk Owner</Form.Label>
                <FormControl className="multi">
                  <Select
                    id="demo-simple-select"
                    value={selectedUser}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => (
                      <div className="list-item-avatar">
                        {selected && (
                          <>
                            <ListItemAvatar>
                              <Avatar
                                alt={selected}
                                src={
                                  users?.find(
                                    (user) => user?.guestName === selected
                                  )?.avatar
                                }
                                style={{
                                  backgroundColor:
                                    backgroundColors[
                                      getColorIndex(selected[0]?.toUpperCase())
                                    ],
                                }}
                              />
                            </ListItemAvatar>
                            <div>
                              <div>{selected}</div>
                              <div>
                                {
                                  users?.find(
                                    (user) => user?.guestName === selected
                                  )?.email
                                }
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    // Add scrolling functionality after 8 guests
                    MenuProps={styles.guestListMenuItoms}
                  >
                    {users?.map((user) => (
                      <MenuItem key={user?.guestName} value={user?.guestName}>
                        <ListItemAvatar>
                          <Avatar
                            alt={user?.guestName}
                            src={user?.avatar}
                            style={{
                              backgroundColor:
                                backgroundColors[
                                  getColorIndex(
                                    user.guestName[0]?.toUpperCase()
                                  ) // Get color index based on the first character
                                ],
                            }}
                          />
                        </ListItemAvatar>
                        {user?.guestName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                {selectedUser?.length > 0 && (
                  <button
                    className="remove-selected-desk-owner-icon"
                    type="button"
                    onClick={() => setSelectedUser("")}
                  >
                    <RxCross1 />
                  </button>
                )}
              </div>
            )}
            <div className="form-element-container">
              <Form.Label className="form-title-lable">Desk Name*</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={"Desk " + deskNumber}
                onChange={handleDeskNameChange}
                required
                ref={deskNameInputRef}
              />
            </div>
            <div className="form-element-container">
              <Form.Label className="form-title-lable">Desk Type*</Form.Label>
              <Form.Select
                aria-label="Default select example desk-select-element"
                value={deskType}
                onChange={(e) => setDeskType(e.target.value)}
              >
                <option value="Dedicated Desk">Dedicated</option>
                <option value="Hot Desk">Hot Desk</option>
              </Form.Select>
            </div>{" "}
            {error && <div className="error-message-box">{error}</div>}
            {/* Desk Area Selector Field */}
            {/* <div className="form-element-container">
              <Form.Label className="form-title-lable">Desk Area*</Form.Label>
              <Form.Select
                aria-label="Default select example desk-select-element"
                value={deskArea}
                onChange={(e) => setDeskArea(e.target.value)}
              >
                <option value="General Area">General Area</option>
                <option value="Upstairs">Upstairs</option>
                <option value="Downstairs">Downstairs</option>
                <option value="Main Floor">Main Floor</option>
                <option value="Outdoor Area">Outdoor Area</option>
                <option value="Conference Room">Conference Room</option>
              </Form.Select>
            </div> */}
            {isEdit ? (
              <div className="edit-desk-btn-box">
                <Button
                  type="submit"
                  variant={MODAL_TYPE.PRIMARY}
                  className={isAdding ? "disabled" : ""}
                  disabled={isAdding || !deskNumber}
                  style={styles.addDeskBtn(isAdding, deskNumber)}
                >
                  {isAdding ? "Loading..." : "Save"}
                </Button>{" "}
                <Button
                  variant={MODAL_TYPE.DANGER}
                  className={isDeleting ? "disabled" : ""}
                  style={styles.deleteDeskBtn(isDeleting)}
                  onClick={() => setDeskDelete(true)}
                >
                  {isDeleting ? "Loading..." : "Delete"}
                </Button>{" "}
                <Button
                  variant={MODAL_TYPE.SECONDARY}
                  onClick={handleCloseAddNewDesk}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="booking-form-save-btn">
                <button
                  type="submit"
                  className={isAdding ? "disabled" : ""}
                  disabled={isAdding || !deskNumber}
                  style={styles.addDeskBtn(isAdding, deskNumber)}
                >
                  {" "}
                  {isAdding ? "Loading..." : "Add"}
                </button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
      <DeskBookingModal
        isEdit={true}
        isNewDesk={!isEdit}
        showModal={confirmModal}
        handleModalClose={handleConfirModalClose}
        message={message}
        bookPerson={bookDesk}
      />{" "}
      <Modal
        className="nav-modal-container"
        show={deskDelete}
        onHide={() => setDeskDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Desk</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this desk?</Modal.Body>
        <Modal.Footer className="nav-modal-footer">
          <Button
            variant={MODAL_TYPE.SECONDARY}
            onClick={() => {
              setDeskDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={MODAL_TYPE.DANGER}
            onClick={() => {
              confirmDeleteDesk();
              setDeskDelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>{" "}
    </>
  );
};

export default AddNewDesk;
