import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  addNewFloorPlan,
  editFloorPlanById,
  getFloorPlanById,
} from "../../services/deskBooking.service.js";
import { ERROR_MESSAGES } from "../../utils/constants/errorMessages";
import {
  DESK_BOOKING_MESSAGES,
  MODAL_TYPE,
  MODAL_TYPE_MESSAGE,
} from "../../utils/constants/messages";
import { DeskBookingModal } from "../index";
import "./DeskBookingTable.css";
import { styles } from "./styleSheet";
import PropTypes from "prop-types";

const UploadFloorPlansImage = ({
  isOpen,
  isClose,
  isEdit,
  selectedImageId,
  resetFloorPlan,
}) => {
  const [deskArea, setDeskArea] = useState("General Area");
  const [isUploading, setIsUploading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  /* The `fetchFloorPlanEdit` function is a `useCallback` used to fetch and set the desk area for editing a floor plan image.*/
  const fetchFloorPlanEdit = useCallback(async () => {
    try {
      const fetchedData = await getFloorPlanById(selectedImageId);
      if (fetchedData) {
        setDeskArea(fetchedData[0]?.ImageName);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
    }
  }, [selectedImageId]);

  /* This `useEffect` hook is responsible for managing side effects in the `UploadFloorPlansImage` component.*/
  useEffect(() => {
    if (isOpen) {
      setIsUploading(false);
      setImageUrl("");
    }
    if (isOpen && isEdit) {
      fetchFloorPlanEdit();
    }
  }, [fetchFloorPlanEdit, isOpen, isEdit]);

  const handleRemoveImage = () => {
    setImageUrl("");
    document.getElementById("floorplanimg").value = "";
  };
  /* The handleImg function checks if the selected file is a valid image (JPEG or PNG) and within the size limit of 10 MB before setting the image URL.*/
  const handleImg = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 10 * 1024 * 1024; // 10 MB

    if (!file) {
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setShowModal(true);
      setIsUploading(false);
      setModalMessage(DESK_BOOKING_MESSAGES.SELECT_VALID_IMAGE_FILE);
      setModalType(MODAL_TYPE.ERROR);
      return;
    }

    if (file.size > maxSize) {
      setShowModal(true);
      setIsUploading(false);
      setModalMessage(DESK_BOOKING_MESSAGES.SELECT_IMAGE_SIZE);
      setModalType(MODAL_TYPE.ERROR);
      return;
    }
    setImageUrl({ imageName: file.name, floorPlanImage: file });
  };

  const handleConfirModalClose = () => {
    setConfirmModal(false);
  };
  const handleCloseAddNewDesk = () => {
    isClose();
  };

  /* The function `handleUploadFloorPlansImages` is responsible for uploading floor plan images, either editing or adding them, and displaying success or error messages accordingly.*/
  const handleUploadFloorPlansImages = async (e) => {
    e.preventDefault();
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("imageName", deskArea);
      if (imageUrl) {
        formData.append("floorPlanImage", imageUrl?.floorPlanImage);
      }
      if (isEdit) {
        await editFloorPlanById(selectedImageId, formData);
      } else {
        await addNewFloorPlan(formData);
      }
      if (isEdit) {
        setMessage(DESK_BOOKING_MESSAGES.DESK_EDITED_SUCCESFULLY);
        resetFloorPlan();
      } else {
        setMessage(DESK_BOOKING_MESSAGES.DESK_ADDED_SUCCESFULLY);
      }
      setConfirmModal(true);
      isClose();
      setIsUploading(false);
    } catch (error) {
      setShowModal(true);
      setModalMessage(
        error?.response?.data?.message || ERROR_MESSAGES.postDataError
      );
      setModalType(MODAL_TYPE.ERROR);
      setIsUploading(false);
      console.error(ERROR_MESSAGES.postDataError, error);
    }
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleCloseAddNewDesk} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="booking-form-heading">
              {isEdit ? `Edit` : ` Upload`} Floor Plans Image
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main">
            <form onSubmit={handleUploadFloorPlansImages}>
              <div className="form-element-container">
                <Form.Label className="form-title-lable">Desk Area*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={deskArea}
                  onChange={(e) => setDeskArea(e.target.value)}
                >
                  <option value="General Area">General Area</option>
                  <option value="Upstairs">Upstairs</option>
                  <option value="Downstairs">Downstairs</option>
                  <option value="Main Floor">Main Floor</option>
                  <option value="Outdoor Area">Outdoor Area</option>
                  <option value="Conference Room">Conference Room</option>
                </Form.Select>
              </div>{" "}
              <div className="form-element-container">
                <Form.Label className="form-title-lable">
                  Floor plans Image*
                </Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleImg}
                  accept="image/*"
                  required={!isEdit}
                  id="floorplanimg"
                />{" "}
              </div>{" "}
              <p className="size-suggestion">(JPEG/PNG, Up to 10 MB)</p>
              <div className="view-floor-img-container">
                {imageUrl && !isEdit && (
                  <div className="floor-img-box">
                    <img
                      src={URL.createObjectURL(imageUrl?.floorPlanImage)}
                      className="uploaded-floor-img"
                      alt={`Floor Plan`}
                      id="floorplanimg"
                    />{" "}
                    <span className="show-image-name">
                      {imageUrl?.imageName}
                    </span>
                    <button
                      className="floor-image-remove-btn"
                      onClick={handleRemoveImage}
                    >
                      &#10006;
                    </button>
                  </div>
                )}
              </div>
              <div className="booking-form-save-btn">
                <button
                  type="submit"
                  className={isUploading ? "disabled" : ""}
                  disabled={isUploading}
                  style={styles.uploadingBtn(isUploading)}
                >
                  {" "}
                  {isUploading ? "Uploading..." : "Upload"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <DeskBookingModal
        isUploadFloorPlanImage={true}
        showModal={confirmModal}
        handleModalClose={handleConfirModalClose}
        message={message}
      />{" "}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === MODAL_TYPE.SUCCESS
              ? MODAL_TYPE_MESSAGE.SUCCESS
              : MODAL_TYPE_MESSAGE.ERROR}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant={
              modalType === MODAL_TYPE.SUCCESS
                ? MODAL_TYPE.SUCCESS
                : MODAL_TYPE.DANGER
            }
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
UploadFloorPlansImage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  selectedImageId: PropTypes.number,
  resetFloorPlan: PropTypes.func,
};
export default UploadFloorPlansImage;
