import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalComponent, Nav } from "../../../../components";
import CreateRoomForm from "../../../../components/MRBS/Form/CreateRoomForm";
import { MRBS_ADMIN_ROUTES } from "../../../../routes/routes";
import { addNewMeetingRoom } from "../../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../../utils/constants/errorMessages";
import {
  COMMON_MESSAGES,
  MODAL_TYPE,
  MODAL_TYPE_MESSAGE,
  MRBS_MESSAGES,
} from "../../../../utils/constants/messages";
import { regexPatterns } from "../../../../utils/helpers";
import "../AdminPages.css";
const AddRooms = () => {
  const [meetRoomName, setMeetRoomName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [status, setStatus] = useState("Active");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const allRooms = MRBS_ADMIN_ROUTES.ALL_ROOMS;
  const handleName = (e) => {
    setMeetRoomName(e.target.value);
  };
  const handleCapacity = (e) => {
    setCapacity(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 10 * 1024 * 1024; // 10 MB

    if (
      selectedFile.type === "application/x-moz-file" ||
      selectedFile.type === "application/x-msdos-program"
    ) {
      setShowModal(true);
      setModalMessage();
      setModalType(MODAL_TYPE.ERROR);
      setIsAdding(false);
      e.target.value = null;
      return;
    }

    if (!allowedTypes.includes(selectedFile.type)) {
      setShowModal(true);
      setIsAdding(false);
      setModalMessage(COMMON_MESSAGES.SELECT_VALID_IMAGE_FILE_ERROR);
      setModalType(MODAL_TYPE.ERROR);
      e.target.value = null;
      return;
    }

    if (selectedFile.size > maxSize) {
      setShowModal(true);
      setIsAdding(false);
      setModalMessage(COMMON_MESSAGES.SELECT_IMAGE_FILE_WITH_SIZE_LIMIT_ERROR);
      setModalType(MODAL_TYPE.ERROR);
      e.target.value = null;
      return;
    }

    setImageUrl(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    const formData = new FormData();
    formData.append("meetRoomName", meetRoomName);
    formData.append("capacity", capacity);
    formData.append("imageurl", imageUrl);
    formData.append("status", status);
    const regex = regexPatterns.lettersAndSpacesRegex;
    if (!regex.test(meetRoomName)) {
      setShowModal(MODAL_TYPE.ERROR);
      setModalMessage(MRBS_MESSAGES.TITLE_ONLY_LETTERS_ERROR);
      setModalType(MODAL_TYPE.DANGER);
      setShowModal(true);
      setIsAdding(false);
      return;
    }
    try {
      const postMeetRoomRes = await addNewMeetingRoom(formData);
      if (postMeetRoomRes) {
        setShowModal(true);
        setModalMessage(MRBS_MESSAGES.MEETING_ROOM_ADDED_SUCCESSFULLY_MESSAGE);
        setModalType(MODAL_TYPE.SUCCESS);
        setIsAdding(false);
      }
    } catch (error) {
      setShowModal(true);
      setIsAdding(false);
      console.error(ERROR_MESSAGES.postMultiFormDataError, error);
    }
  };

  const closeModal = () => {
    setShowModal(false);

    if (modalType === MODAL_TYPE.SUCCESS) {
      navigate(allRooms);
    }
  };

  return (
    <>
      <Nav isMRBSAdmin={true} />
      <div className="add-room-container">
        <div className="admin-add-rooms">
          <div className="booking-form-card">
            <CreateRoomForm
              isEdit={false}
              handleSubmit={handleSubmit}
              meetRoomName={meetRoomName}
              handleName={handleName}
              handleImage={handleImage}
              handleStatus={handleStatus}
              status={status}
              imageUrl={imageUrl}
              capacity={capacity}
              handleCapacity={handleCapacity}
              isAdding={isAdding}
              isEditing={false}
            />
          </div>
        </div>
        <ModalComponent
          isShow={showModal}
          isHide={closeModal}
          title={
            modalType === MODAL_TYPE.SUCCESS
              ? MODAL_TYPE_MESSAGE.SUCCESS
              : MODAL_TYPE_MESSAGE.ERROR
          }
          message={modalMessage}
          variant={
            modalType === MODAL_TYPE.SUCCESS
              ? MODAL_TYPE.SUCCESS
              : MODAL_TYPE.DANGER
          }
        />
      </div>
    </>
  );
};

export default AddRooms;
