import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  CurrentStatusCard,
  DataNotFound,
  Loading,
} from "../../../../components";
import { styles } from "../../../../components/styleSheet";
import { getMeetingRoomActiveBookings } from "../../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../../utils/constants/errorMessages";
import { filterFutureBookings } from '../../../../utils/helpers';
import "./RoomBooking.css";
function ActiveBooking({ name, ...props }) {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [currentBookingData, setCurrentBookingData] = useState([]);
  const [upcomingBookingData, setUpcomingBookingData] = useState([]);
  const getActiveBookingsData = async () => {
    try {
      const bookingDataRes = await getMeetingRoomActiveBookings();
      //  filter future bookings
      const filterTodaysBooking = filterFutureBookings(bookingDataRes.todays_bookings);
      setCurrentBookingData(filterTodaysBooking);
      setUpcomingBookingData(bookingDataRes?.upcoming_bookings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(ERROR_MESSAGES.fetchDataError, error);
      setCurrentBookingData([]);
      setUpcomingBookingData([]);
    }
  };
  const handleUpdate = () => {
    getActiveBookingsData();
  };

  return (
    <>
      <button
        onClick={() => {
          getActiveBookingsData();
          setShow(true);
        }}
        className="active-booking-label"
        title="View Your Active Bookings"
      >
        Active Bookings
      </button>

      <Offcanvas
        style={styles.activeBookingsCanvasWidth}
        show={show}
        onHide={() => setShow(false)}
        {...props}
      >
        <Offcanvas.Header closeButton className="ms-auto" />
        <Offcanvas.Body>
          <div>
            <h2 className="off-canvas-title">Today's Booking</h2>
            {loading ? (
              <Loading />
            ) : (
              <>
                {currentBookingData?.length === 0 ? (
                  <DataNotFound error="Current Bookings Not Found" />
                ) : (
                  <div className="currentStatus">
                    <table
                      className="table table-striped table-bordered"
                      style={styles.activeBookingsTable}
                    >
                      <thead className="thead-dark">
                        <tr className="dark-row">
                          <th scope="col" className="text-center">
                            Sr.No.
                          </th>
                          <th scope="col" className="text-center">
                            Room Name
                          </th>
                          <th scope="col" className="text-center">
                            Organizer Name
                          </th>
                          <th scope="col" className="text-center">
                            Date
                          </th>
                          <th scope="col" className="text-center">
                            Start Time
                          </th>
                          <th scope="col" className="text-center">
                            End Time
                          </th>
                          <th scope="col" className="text-center">
                            Edit
                          </th>
                          <th scope="col" className="text-center">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentBookingData?.map((item, index) => (
                          <CurrentStatusCard
                            key={item.id}
                            index={index}
                            id={item.id}
                            Rname={item.meetingRoomName}
                            Issuer={item.userName}
                            lname={item.lastName}
                            Date={item.date}
                            from={item.startTime}
                            to={item.endTime}
                            onUpdate={handleUpdate}
                            status={item.status}
                            userId={item.userId}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
          <hr />
          <>
            <h2 className="off-canvas-title">Upcoming Bookings</h2>
            {loading ? (
              <Loading />
            ) : (
              <>
                {upcomingBookingData?.length === 0 ? (
                  <DataNotFound error="Upcoming Bookings Not Found" />
                ) : (
                  <div className="currentStatus">
                    <table
                      className="table table-striped table-bordered"
                      style={styles.activeBookingsTable}
                    >
                      <thead className="thead-dark">
                        <tr className="dark-row">
                          <th scope="col" className="text-center">
                            Sr.No.
                          </th>
                          <th scope="col" className="text-center">
                            Room Name
                          </th>
                          <th scope="col" className="text-center">
                            Organizer Name
                          </th>
                          <th scope="col" className="text-center">
                            Date
                          </th>
                          <th scope="col" className="text-center">
                            Start Time
                          </th>
                          <th scope="col" className="text-center">
                            End Time
                          </th>
                          <th scope="col" className="text-center">
                            Edit
                          </th>
                          <th scope="col" className="text-center">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {upcomingBookingData?.map((item, index) => (
                          <CurrentStatusCard
                            key={item.id}
                            index={index}
                            id={item.id}
                            Rname={item.meetingRoomName}
                            Issuer={item.userName}
                            lname={item.lastName}
                            Date={item.date}
                            from={item.startTime}
                            to={item.endTime}
                            onUpdate={handleUpdate}
                            status={item.status}
                            userId={item.userId}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default ActiveBooking;
