import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { COMMON_MESSAGES, MODAL_TYPE } from "../../utils/constants/messages";

const ShowConfirmationPopup = ({ isShow, isHide, isCall }) => {
  return (
    <Modal show={isShow} onHide={isHide}>
      <Modal.Header closeButton>
        <Modal.Title>{MODAL_TYPE.CONFIRMATION}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{COMMON_MESSAGES.CONFIRMATION_LOGOUT_MESSAGE}</Modal.Body>
      <Modal.Footer>
        <Button variant={MODAL_TYPE.SECONDARY} onClick={isHide}>
          No
        </Button>
        <Button variant={MODAL_TYPE.PRIMARY} onClick={isCall}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ShowConfirmationPopup.propTypes = {
  isShow: PropTypes.bool,
  isHide: PropTypes.func,
  isCall: PropTypes.func,
};
export default ShowConfirmationPopup;
