import React from "react";
import { Link } from "react-router-dom";
import HeroImg from "../../../assets/images/Heroimg.png";
import TaLogo from "../../../assets/images/talogoblack.jpeg";
import "../../../modules/MRBS/user/UserDashboard/UserDashboard.css";
import { MRBS_USER_ROUTES } from "../../../routes/routes";
import PropTypes from "prop-types";

const Hero = ({ isAdmin }) => {
  const heading =
    " Your One-Stop Solution for Effortless Meeting Room Management.";
  const description =
    "Meeting room booking system provides a seamless and efficient solution for reserving meeting rooms in our office. With our user-friendly interface, you can easily browse through available rooms, select the desired time slot, and book it instantly.";
  return (
    <div className="hero-container">
      <div className="hero-text-content">
        <h1 className="hero-heading">{heading}</h1>
        <p className="hero-description">{description}</p>
        <div>
          <Link to={MRBS_USER_ROUTES.BOOKING}>
            <button className="knowmore">
              <span>
                <img className="btn-ta-logo" src={TaLogo} alt="TAlogo" /> Book a
                Meeting Room
              </span>
            </button>
          </Link>
          {/* <Link
            to={
              isAdmin ? DBS_ADMIN_ROUTES.DASHBOARD : DBS_USER_ROUTES.DASHBOARD
            }
          >
            <button className="knowmore">
              <span>
                <img src={TaLogo} alt="TAlogo" className="btn-ta-logo" /> Book a
                desk
              </span>
            </button>
          </Link> */}
        </div>
      </div>
      <div className="hero-image-box">
        <img className="hero-box-image" src={HeroImg} alt="meetroom-seat" />
      </div>
    </div>
  );
};
Hero.propTypes = {
  isAdmin: PropTypes.bool,
};
export default Hero;
