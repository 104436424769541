import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import "./Form.css";
function CreateRoomForm({
  handleNavigateToAdminRooms,
  isEdit,
  handleSubmit,
  meetRoomName,
  handleName,
  handleImage,
  capacity,
  status,
  handleStatus,
  handleCapacity,
  isAdding,
  isEditing,
}) {
  let buttonText;

  switch (true) {
    case isAdding:
      buttonText = "Loading...";
      break;
    case isEdit && isEditing:
      buttonText = "Loading";
      break;
    case isEdit:
      buttonText = "Save";
      break;
    default:
      buttonText = "Add";
      break;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="room-name-heading">
        <h1 className="booking-form-heading">
          {isEdit ? `Edit a Meeting Room` : `Add a Meeting Room`}
        </h1>
        {isEdit && (
          <button
            className="cancel-btn"
            type="button"
            onClick={handleNavigateToAdminRooms}
          >
            <ImCross />
          </button>
        )}
      </div>
      <hr />
      <div className="create-room-form-container">
        <div className="create-room-form-box">
          <Form.Label className="form-label-text">Room Name *</Form.Label>
          <div className="form-input-box">
            <Form.Control
              type="text"
              name="title"
              minLength={3}
              maxLength={15}
              value={meetRoomName}
              onChange={handleName}
              required
            />
          </div>
        </div>
        <div className="create-room-form-box">
          <Form.Label className="form-label-text">Room Image *</Form.Label>
          <div className="form-input-box">
            <Form.Control
              type="file"
              name="file"
              onChange={handleImage}
              accept="image/*"
              required={!isEdit}
            />
          </div>
        </div>{" "}
        <div className="create-room-form-box">
          <Form.Label className="form-label-text">Capacity *</Form.Label>
          <div className="form-input-box">
            <Form.Control
              type="number"
              value={capacity}
              onChange={handleCapacity}
              required
              min="1"
            />
          </div>
        </div>{" "}
        <div className="create-room-form-box">
          <Form.Label className="form-label-text">Status *</Form.Label>
          <div className="form-input-box">
            <Form.Select
              className="form-selector"
              aria-label="Default select example"
              value={status}
              onChange={handleStatus}
            >
              <option value="Active">Active</option>
              <option value="InActive">Inactive</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="booking-form-save-btn">
        <button
          type="submit"
          className={isAdding || (isEdit && isEditing) ? "disabled" : ""}
          disabled={isAdding || (isEdit && isEditing)}
        >
          {buttonText}
        </button>
      </div>
    </Form>
  );
}
CreateRoomForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  meetRoomName: PropTypes.string.isRequired,
  handleName: PropTypes.func.isRequired,
  handleImage: PropTypes.func.isRequired,
  capacity: PropTypes.number.isRequired,
  handleCapacity: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  handleStatus: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleNavigateToAdminRooms: PropTypes.func,
};
export default CreateRoomForm;
