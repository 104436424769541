import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
import { GuestListContext } from "../../../context/GuestListContext";
import { MRBS_USER_ROUTES } from "../../../routes/routes";
import { bookMeetingRoom } from "../../../services/mrbs.service";
import {
  ERROR_MESSAGES,
  STATUS_CODES,
} from "../../../utils/constants/errorMessages";
import {
  MODAL_TYPE,
  MODAL_TYPE_MESSAGE,
  MRBS_MESSAGES,
  ROOM_STATUS,
} from "../../../utils/constants/messages";
import { getStatusColor } from "../../../utils/helpers";
import { ModalComponent, RoomBookingForm } from "../../index";
import RoomSlotsDetails from "../RoomSlotsDetails/RoomSlotsDetails";
import "./RoomCard.css";
function BookRoomCards(props) {
  const [viewBookingModalShow, setViewBookingModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [description, setDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShowUn, setModalShowUn] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalVariant, setModalVariant] = useState("");
  const [isBooking, setIsBooking] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const guestList = useContext(GuestListContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const MeetRoomID = props.id;
  const redirectBooking = MRBS_USER_ROUTES.BOOKING;
  const redirectLogin = MRBS_USER_ROUTES.LOGIN;
  const handleCloseViewBookingModal = () => {
    setViewBookingModalShow(false);
  };
  useEffect(() => {
    const formattedOptions = guestList?.map((guest) => ({
      guestName: guest?.guestName,
      guestEmail: guest?.guestEmail,
    }));
    setSuggestions(formattedOptions);
  }, [guestList]);

  const onSelectOptions = (selectedList, selectedItem) => {
    if (selectedItem?.guestName && selectedItem?.guestEmail) {
      const updatedSuggestions = suggestions?.filter(
        (option) => option?.guestName !== selectedItem?.guestName
      );
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        {
          guestName: selectedItem?.guestName,
          guestEmail: selectedItem?.guestEmail,
        },
      ]);
      setSuggestions(updatedSuggestions);
    }
  };

  const onRemoveOptions = (selectedList, removedItem, event) => {
    if (removedItem?.guestName && removedItem?.guestEmail) {
      const isItemInSuggestions = suggestions?.some(
        (option) =>
          option?.guestName === removedItem?.guestName &&
          option?.guestEmail === removedItem?.guestEmail
      );
      if (!isItemInSuggestions) {
        suggestions?.push({
          guestName: removedItem?.guestName,
          guestEmail: removedItem?.guestEmail,
        });
      }
      if (event && event.keyCode === 8 && suggestions?.length > 0) {
        const firstSuggestion = suggestions[0];
        onSelectOptions(selectedList, firstSuggestion);
      }
      const updatedSelectedOptions = selectedOptions?.filter(
        (option) => option?.guestName !== removedItem?.guestName
      );
      setSelectedOptions(updatedSelectedOptions);
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndtime = (e) => {
    setEndTime(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleCheckbox = (e) => {
    setIsPrivate(e.target.checked)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBooking(true);

    const guestsArray = selectedOptions?.map((option) => {
      return { guestName: option?.guestName, guestEmail: option?.guestEmail };
    });
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);
    const formData = {
      meetRoomId: MeetRoomID,
      title: title,
      date: date.split("-").reverse().join("/"),
      startTime: startTime,
      endTime: endTime,
      guests: guestsArray,
      description: description,
      clstartTime: startDateTime,
      clendTime: endDateTime,
      isPrivate: isPrivate,
    };

    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    const selectedDate = new Date(`${date}T${startTime}`);
    const selectedDateTime = selectedDate.getTime();

    if (selectedDateTime < currentDateTime) {
      setIsBooking(false);
      setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
      setModalMessage(MRBS_MESSAGES.CANNOT_BOOK_PAST_SLOT_ERROR);
      setModalVariant(MODAL_TYPE.DANGER);
      setModalShow(true);
      return;
    }
    const startTimeStamp = new Date(`${date}T${startTime}`).getTime();
    const endTimeStamp = new Date(`${date}T${endTime}`).getTime();
    if (endTimeStamp <= startTimeStamp) {
      setIsBooking(false);
      setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
      setModalMessage(MRBS_MESSAGES.END_TIME_GREATER_THAN_START_TIME_ERROR);
      setModalVariant(MODAL_TYPE.DANGER);
      setModalShow(true);
      return;
    }

    try {
      const booksRoomRes = await bookMeetingRoom(formData);
      if (booksRoomRes) {
        setIsBooking(false);
        setModalTitle(MODAL_TYPE_MESSAGE.SUCCESS);
        setModalMessage(MRBS_MESSAGES.MEETING_ROOM_BOOKED_SUCCESSFULLY_MESSAGE);
        setModalVariant(MODAL_TYPE.SUCCESS);
        setModalShow(true);
        setTitle("");
        setDate("");
        setStartTime("");
        setEndTime("");
        setDescription("");
        setIsPrivate(false);
        setSelectedOptions([]);
      } else {
        setIsBooking(false);
        setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
        setModalMessage(ERROR_MESSAGES.somethingWrongError);
        setModalVariant(MODAL_TYPE.DANGER);
        setModalShow(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === STATUS_CODES.BAD_REQUEST) {
          setIsBooking(false);
          setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
          setModalMessage(MRBS_MESSAGES.MEETING_ROOM_SLOT_ALREADY_OCCUPIED);
          setModalVariant(MODAL_TYPE.ERROR);
          setModalShow(true);
        } else if (error.response.status === STATUS_CODES.UNAUTHORIZED) {
          setIsBooking(false);
          setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
          setModalMessage(ERROR_MESSAGES.unauthorizedError);
          setModalVariant(MODAL_TYPE.DANGER);
          setModalShowUn(true);
        } else {
          setIsBooking(false);
          setModalTitle(MODAL_TYPE_MESSAGE.ERROR);
          setModalMessage(ERROR_MESSAGES.somethingWrongError);
          setModalVariant(MODAL_TYPE.DANGER);
          setModalShow(true);
        }
      }
      setModalShow(true);
      console.error(ERROR_MESSAGES.postDataError, error);
    }
  };

  const handleCloseModal = () => {
    setModalShow(false);
    if (modalVariant === MODAL_TYPE.SUCCESS) {
      setShow(false);
      navigate(redirectBooking);
    }
  };

  const handleNavigateToAdminRooms = () => {
    setShow(false);
  };

  const statusColor = getStatusColor(props.status);
  const statusStyle = {
    color: statusColor,
    borderColor: statusColor,
  };

  const handleCloseModalUn = () => {
    setModalShow(false);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    navigate(redirectLogin);
  };

  const handleRoomDetailsClick = async (e) => {
    e.preventDefault();
    setViewBookingModalShow(true);
  };

  return (
    <div className="room-card-box">
      <img className="meeting-room-img" src={props.img} alt="meeting-room" />
      <div className="meeting-room-details">
        <div className="room-card-name-box">
          <h1 className="room-card-name">{props.name}</h1>
          <div className="room-card-status" style={statusStyle}>
            {props.status === ROOM_STATUS.ACTIVE
              ? ROOM_STATUS.ACTIVE
              : ROOM_STATUS.Inactive}
          </div>
        </div>

        <div className="room-card-capicity">Capacity: {props.inside}</div>
        <div className="room-card-btn">
          <Button
            className={`room-card-button`}
            variant={MODAL_TYPE.DARK}
            onClick={() => {
              if (props.status === ROOM_STATUS.ACTIVE) {
                handleShow();
              }
            }}
          >
            {props.status === ROOM_STATUS.ACTIVE
              ? "Book a Slot"
              : "Booking Not Allowed"}
          </Button>
          <Button
            className="room-card-button"
            onClick={handleRoomDetailsClick}
            variant={MODAL_TYPE.DARK}
          >
            Room Details
          </Button>
        </div>
      </div>{" "}
      <RoomSlotsDetails
        isShow={viewBookingModalShow}
        onHide={handleCloseViewBookingModal}
        roomName={props.name}
        roomId={MeetRoomID}
      />
      <RoomBookingForm
        isShow={show}
        isHide={handleClose}
        isFormSubmit={handleSubmit}
        RoomName={props.name}
        crossBtn={handleNavigateToAdminRooms}
        title={title}
        handleTitle={handleTitle}
        date={date}
        handleDateChange={handleDateChange}
        startTime={startTime}
        handleStartTime={handleStartTime}
        endTime={endTime}
        handleEndtime={handleEndtime}
        suggestions={suggestions}
        onSelectOptions={onSelectOptions}
        onRemoveOptions={onRemoveOptions}
        selectedOptions={selectedOptions}
        description={description}
        handleDescription={handleDescription}
        isBooking={isBooking}
        isPrivate={isPrivate}
        handleCheckbox={handleCheckbox}
      />
      <ModalComponent
        isShow={modalShow}
        isHide={handleCloseModal}
        title={modalTitle}
        message={modalMessage}
        variant={modalVariant}
      />
      <ModalComponent
        isShow={modalShowUn}
        isHide={handleCloseModalUn}
        title={modalTitle}
        message={modalMessage}
        variant={modalVariant}
      />
    </div>
  );
}
export default BookRoomCards;
